import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useIsMounted } from 'usehooks-ts'

import { selectMaintenanceStatus } from '@hozana/api/selectors'
import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'
import { getOs } from '@hozana/screen/functions/getOs'
import { isKindOfApp } from '@hozana/screen/functions/isKindOfApp'
import { useScreen } from '@hozana/screen/useScreen'
import { QA } from '@hozana/tests/constants'
import { UTM } from '@hozana/tracking/constants'
import { GTM } from '@hozana/tracking/gtm'

import { Cell } from 'elements/layout/Cell'
import { Div } from 'elements/layout/Div'
import { Overlay } from 'elements/layout/Overlay'
import { Row } from 'elements/layout/Row'

import { closeNavigation, openNavigation } from 'general/actions'
import { FULL_STATIC_PAGES, HEADER_ID, HEADER_ITEM, QUERY_ACTION, STATUS_TYPE } from 'general/constants'
import { useHeaderMap } from 'general/hooks/useHeaderMap'
import { useTitle } from 'general/hooks/useTitle'
import { AlertManager } from 'general/managers/alert/AlertManager'
import { isNavigationOpen as selectNavigationOpen } from 'general/selectors'
import { Navigation } from 'general/structure/Navigation'
import { PAGE } from 'routes/constants'

import { CommunitiesFilterButton } from 'modules/community/components/CommunitiesFilterButton'
import { CommunitiesFilterPopover } from 'modules/community/components/CommunitiesFilterPopover'
import { COMMUNITY_PAGE_TABS } from 'modules/community/constants'
import { SearchForm } from 'modules/community/pages/CommunitiesPage/SearchForm'
import { selectCommunity } from 'modules/community/selectors'
import { getAppStoresLinks } from 'modules/home/functions'
import { useIntentionNotificationsCount } from 'modules/intention/hooks/useIntentionNotificationsCount'
import { isPublicationPublished } from 'modules/publication/functions'
import { selectPublication, selectPublicationCommunity } from 'modules/publication/selectors'
import { useUser } from 'modules/user/hooks/useUser'

import { NavigationButton } from '../Navigation/NavigationButton'
import { AvoidanceNavigation } from './AvoidanceNavigation'
import { GoBack } from './GoBack'
import {
  CommunitiesTab,
  CommunityJoinButtons,
  DonationLink,
  FeedPageTab,
  HeaderItemWrapper,
  HeaderLinkButton,
  InviteBlock,
  LanguageItemBlock,
  LoginButton,
  LogoBlock,
  ShareSocialBlock,
  SignupButton,
  TitlePage,
} from './HeaderItemsComponent'
import { HeaderWrapper } from './HeaderWrapper'
import { StatusTab } from './StatusTab'
import { SubHeaderMobile } from './SubHeaderMobile'

export type THeaderProps = {
  pageTitle?: string
}

const StyledCell = styled(Cell)`
  transition: all 300ms linear;
`

export const Header: React.FC<THeaderProps> = ({ pageTitle }) => {
  const router = useRouter()
  const screen = useScreen()
  const theme = useTheme()
  const me = useUser()
  const dispatch = useDispatch()
  const title = useTitle(pageTitle)
  const isMounted = useIsMounted()

  const intentionTabNotificationsCount = useIntentionNotificationsCount()

  const { pathname, query } = router

  const [isSearching, setIsSearching] = useState(false)
  const [isOpenCommunitiesFilterPopover, setOpenCommunityFilterPopover] = useState(false)

  const currentPublication = useSelector((state) => selectPublication(state, Number(query.publicationId)))
  const currentCommunity = useSelector((state) =>
    ([PAGE.PUBLICATION_VIEW, PAGE.PUBLICATION_EDIT] as PAGE[]).includes(pathname)
      ? selectPublicationCommunity(state, Number(query.publicationId))
      : selectCommunity(state, Number(query.communityId)),
  )
  const isNavigationOpen = useSelector((state) => selectNavigationOpen(state))
  const isMaintenanceStateActive = useSelector((state) => selectMaintenanceStatus(state))

  const toggleNavigation = () => {
    const navigationWillOpen = !isNavigationOpen
    if (navigationWillOpen) {
      GTM.trackEvent(GTM.EVENTS.MENU)
      dispatch(openNavigation())
    } else {
      dispatch(closeNavigation())
    }
  }

  const renderSection = (items: HEADER_ITEM[]) => {
    // Do not display the header on SocialAuthPage
    if (router.pathname === PAGE.AUTH_SOCIAL) {
      return null
    }

    return items.map((item) => {
      switch (item) {
        case HEADER_ITEM.NAVIGATION_BUTTON:
          return !['it', 'pl'].includes(router.locale) ? (
            <NavigationButton key={item} toggleNavigation={toggleNavigation} isNavigationOpen={isNavigationOpen} />
          ) : null
        case HEADER_ITEM.FEED_PAGE_TAB:
          return (
            <FeedPageTab
              key={item}
              data-testid={QA.COMMON.HEADER.FEED_PAGE_LINK}
              selected={pathname === PAGE.USER_FEED}
            />
          )
        case HEADER_ITEM.COMMUNITIES_TAB:
          return (
            <CommunitiesTab
              key={item}
              data-testid={QA.COMMON.HEADER.COMMUNITIES_PAGE_LINK}
              selected={pathname === PAGE.COMMUNITY_LIST}
            />
          )
        case HEADER_ITEM.TITLE_PAGE:
          return (
            <TitlePage
              key={item}
              bigTitle={
                !(
                  [
                    PAGE.PUBLICATION_VIEW,
                    PAGE.COMMUNITY_VIEW,
                    PAGE.COMMUNITY_VIEW_ADMIN,
                    PAGE.COMMUNITY_VIEW_LANDING,
                    PAGE.COMMUNITY_VIEW_UNPUBLISHED,
                  ] as PAGE[]
                ).includes(pathname)
              }
            >
              {title}
            </TitlePage>
          )
        case HEADER_ITEM.SEARCH_FORM:
          return (
            /** Screen is defined to "xs: true" by default in SSR, even on desktop,
             * so we force that component to be visible only on mobile, by CSS
             */
            <Row nowrap key={item} visible="xs">
              <Cell flex="1">
                <HeaderItemWrapper align="middle" w="100%" p="0 10px">
                  <SearchForm
                    opacity={0.8}
                    bg="black10"
                    color="black50"
                    borderColor="black30"
                    buttonProps={{ hoverBg: 'black50', hoverColor: 'black70' }}
                    fontSize="1em"
                    onFocus={() => setIsSearching(true)}
                    onBlur={() => {
                      // Without setTimeout, the onBlur event first fires which moves the elements,
                      // and moves the button away from under the pointer when cliking on the clear button
                      setTimeout(() => {
                        if (isMounted) {
                          setIsSearching(false)
                        }
                      }, 100)
                    }}
                  />
                </HeaderItemWrapper>
              </Cell>
              {(router?.query.keyword || me.admin) && (
                <Cell align="middleCenter" w={theme.sizes.headerItem}>
                  <CommunitiesFilterButton
                    onClick={() => setOpenCommunityFilterPopover(true)}
                    color="black50"
                    hoverColor="black"
                  />
                </Cell>
              )}
            </Row>
          )
        case HEADER_ITEM.MESSAGE_TAB:
          return (
            me.isLogged && (
              <StatusTab
                key={item}
                data-testid={QA.COMMON.HEADER.MESSAGES_PAGE_LINK}
                type={STATUS_TYPE.MESSAGES}
                selected={pathname === PAGE.CONVERSATIONS}
              />
            )
          )
        case HEADER_ITEM.INTENTION_TAB:
          return (
            me.isLogged && (
              <StatusTab
                key={item}
                isDot
                type={STATUS_TYPE.INTENTIONS}
                selected={pathname === PAGE.INTENTIONS}
                number={intentionTabNotificationsCount}
              />
            )
          )
        case HEADER_ITEM.LOGO:
          return <LogoBlock key={item} isLogged={me.isLogged} />
        case HEADER_ITEM.LANGUAGE_ITEM_BLOCK:
          return <LanguageItemBlock key={item} />
        case HEADER_ITEM.DONATION:
          return <DonationLink key={item} />
        case HEADER_ITEM.LOGIN_BUTTON:
          return !isMaintenanceStateActive && <LoginButton key={item} />
        case HEADER_ITEM.SIGN_UP_BUTTON:
          return !isMaintenanceStateActive && <SignupButton key={item} />
        case HEADER_ITEM.SHARE_SOCIAL_BLOCK:
          return <ShareSocialBlock key={item} community={currentCommunity} publication={currentPublication} />
        case HEADER_ITEM.INVITE_BLOCK:
          return <InviteBlock key={item} community={currentCommunity} />
        case HEADER_ITEM.COMMUNITY_JOIN_BUTTONS:
          return <CommunityJoinButtons key={item} community={currentCommunity} />
        case HEADER_ITEM.BACK_TO_COMMUNITY_ADMIN:
          return (
            <GoBack
              key={item}
              to={{
                pathname: PAGE.COMMUNITY_VIEW_ADMIN,
                query: {
                  communityId: currentCommunity.id,
                  communitySlug: currentCommunity.slug,
                },
              }}
            >
              trans:common:community.return
            </GoBack>
          )
        case HEADER_ITEM.BACK_TO_PUBLICATION:
          return (
            <GoBack
              key={item}
              asButton
              reload
              to={
                isPublicationPublished(currentPublication)
                  ? {
                      pathname: PAGE.PUBLICATION_VIEW,
                      query: {
                        publicationId: currentPublication.id,
                        publicationSlug: currentPublication.slug,
                      },
                    }
                  : {
                      pathname: PAGE.COMMUNITY_VIEW_ADMIN,
                      query: {
                        communityId: currentCommunity.id,
                        communitySlug: currentCommunity.slug,
                        tab: COMMUNITY_PAGE_TABS.SCHEDULED_AND_DRAFT,
                      },
                    }
              }
            >
              trans:common:publication.view
            </GoBack>
          )
        case HEADER_ITEM.GO_BACK:
          return <GoBack key={item} />
        case HEADER_ITEM.TO_HOZANA_LINK:
          return (
            <HeaderLinkButton to={{ pathname: PAGE.HOME }} key={item}>
              trans:common:words.discover-hozana
            </HeaderLinkButton>
          )
        case HEADER_ITEM.LOGIN_LINK:
          return (
            <HeaderLinkButton to={{ pathname: PAGE.HOME, query: { action: QUERY_ACTION.LOGIN } }} key={item}>
              trans:common:auth.login.login
            </HeaderLinkButton>
          )
        case HEADER_ITEM.OPEN_IN_APP: {
          if (isKindOfApp()) return null

          /** Screen is defined to "xs: true" by default in SSR, even on desktop,
           * so we force that component to be visible only on mobile, by CSS
           */
          return (
            <HeaderLinkButton to={getAppStoresLinks(UTM.MEDIUM.PUBLICATION_HEADER, getOs())} key={item} visible="xs">
              trans:common:words.open-in-app
            </HeaderLinkButton>
          )
        }
        default:
          return null
      }
    })
  }

  const { desktop, mobile } = useHeaderMap({ community: currentCommunity, publication: currentPublication })

  return (
    <>
      {isNavigationOpen && <Overlay onClick={toggleNavigation} zIndex="subMenuOverlay" />}
      {!screen.xs && <AvoidanceNavigation toggleNavigation={toggleNavigation} />}

      <HeaderWrapper
        bg={CONF_KEYS.API_URL.startsWith('https://hozana.org/') && CONF_KEYS.ENV === 'dev' ? 'darkRed' : undefined}
      >
        <AlertManager />
        <Div as="nav" id={HEADER_ID} role="navigation" p="1em">
          <Row nowrap color="black70">
            {screen.xs ? (
              <>
                {!isSearching && <Cell h="100%">{renderSection(mobile.left)}</Cell>}
                <Cell h="100%" display="flex" flex="1">
                  {renderSection(mobile.middle)}
                </Cell>
                {!isSearching && (
                  <Cell h="100%" display="flex">
                    {renderSection(mobile.right)}
                  </Cell>
                )}
              </>
            ) : (
              <>
                <Cell h="100%" flex="1" align="middleLeft">
                  {renderSection(desktop.left)}
                </Cell>
                <Cell h="100%" align="middleCenter">
                  {renderSection(desktop.middle)}
                </Cell>
                <StyledCell h="100%" flex="1" align="middleRight" m="0">
                  {!['it', 'pl'].includes(router.locale) && renderSection(desktop.right)}
                </StyledCell>
              </>
            )}
          </Row>
        </Div>
      </HeaderWrapper>
      {pathname === PAGE.COMMUNITY_LIST && screen.xs && (
        <CommunitiesFilterPopover
          isOpenPopover={isOpenCommunitiesFilterPopover}
          onClosing={() => setOpenCommunityFilterPopover(false)}
        />
      )}
      {!FULL_STATIC_PAGES.includes(pathname) &&
        !['it', 'pl'].includes(router.locale) &&
        [...mobile.left, ...desktop.left].includes(HEADER_ITEM.NAVIGATION_BUTTON) && (
          <Navigation toggleNavigation={toggleNavigation} isOpen={isNavigationOpen} />
        )}
      {(pathname === PAGE.GUIDE ? screen.xs && me.isLogged : screen.xs) && <SubHeaderMobile />}
    </>
  )
}
