import { TUrlObject, singletonRouter } from '@hozana/router'
import { pathIsOrStartsWith, translateUrl } from '@hozana/router/functions'
import { mobileDetect } from '@hozana/screen/functions/mobileDetect'
import { cookie } from '@hozana/storage/cookies'
import { GTM, type TGTMEventObjet } from '@hozana/tracking/gtm'

import { COOKIES, COOKIES_CONFIG } from 'general/managers/cookies/constants'
import type { TLocale } from 'i18n/constants'
import { PAGE, REWRITTEN_PAGES } from 'routes/constants'

export const setLangCookie = (locale: string) => {
  cookie.save(COOKIES.hideBannerLang, true, COOKIES_CONFIG.ONE_YEAR)
  cookie.save('NEXT_LOCALE', locale, COOKIES_CONFIG.ONE_YEAR)
}

export const getLangCookie = () => cookie.load<TLocale>('NEXT_LOCALE')

const wait = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

/** Determine if the path is a widget path */
export const getIsWidget = (path?: string) =>
  ([PAGE.COMMUNITY_WIDGET, PAGE.INTENTIONS_WIDGET] as string[]).includes(path) ||
  pathIsOrStartsWith(path, '/widget', '/en/widget', '/pt/widget', '/es/widget')

/** Determine if the path is a guide path */
export const getIsGuide = (path: string) =>
  path === PAGE.GUIDE ||
  pathIsOrStartsWith(
    path,
    /* FR */
    '/bible',
    '/fetes',
    '/priere',
    '/saints',
    '/catechisme',
    '/miracles-et-apparitions',
    '/chapelet',
    '/meditation',
    /* EN */
    'en/bible',
    'en/feast',
    'en/prayer',
    'en/saints',
    'en/catechism',
    'en/miracles-and-apparitions',
    'en/rosary',
    'en/meditation',
    /* ES */
    'es/biblia',
    'es/fiestas',
    'es/oracion',
    'es/santos',
    'es/catecismo',
    'es/milagros-y-apariciones',
    'es/rosario',
    'es/meditacion',
    /* PT */
    'pt/biblia',
    'pt/festas',
    'pt/oracao',
    'pt/santos',
    'pt/catecismo',
    'pt/milagres-e-aparicoes',
    'pt/rosario',
    'pt/meditacao',
    /* IT */
    'it/bibbia',
    'it/feste',
    'it/preghiera',
    'it/santi',
    'it/catechismo',
    'it/miracoli-e-apparizioni',
    'it/rosario',
    'it/meditazione',
    /* PL */
    'pl/biblia',
    'pl/swieto',
    'pl/modlitwa',
    'pl/swiety',
    'pl/katechizm',
    'pl/cuda-i-objawienia',
    'pl/rozaniec',
    'pl/medytacja',
  )

/**************************************************/
/*** Connect details and redirection on connect ***/
/**************************************************/

export type TConnectDetails = {
  displayWizardOnSignup?: boolean
  redirectUrl?: TUrlObject
  /** No redirection if set to true */
  skipRedirect?: boolean
  socialName?: string
  locale?: TLocale
  newUser?: boolean
  email?: string
  reload?: boolean
  /**
   * Used instead of redirectUrl:
   * - when the app just reloaded (on social redirection on mobile or on reset password)
   * - or when the app will reload (if reload is set to true)
   */
  reloadUrl?: TUrlObject | string
  /** GTM data to be sent before redirecting */
  gtmData?: TGTMEventObjet[]
}

/**
 * Saves connect details as a cookie to handle redirection on connect.
 */
export const saveConnectDetails = (connectDetails: TConnectDetails): void =>
  cookie.save(COOKIES.connectDetails, JSON.stringify(connectDetails), COOKIES_CONFIG.SESSION)

/**
 * Get connect details previously stored in cookies.
 */
export const getConnectDetails = () => cookie.load<TConnectDetails>(COOKIES.connectDetails) || {}

/**
 * Remove connect details cookie.
 */
export const removeConnectDetails = (): void => cookie.remove(COOKIES.connectDetails, COOKIES_CONFIG.SESSION)

/**
 * Update a value stored in the connect details cookie
 *
 * ⚠ `updateConnectDetails({ redirectUrl: undefined })` will indeed set redirectUrl to undefined
 */
export const updateConnectDetails = (connectDetails: TConnectDetails): void => {
  const currentConnectDetails = getConnectDetails()
  saveConnectDetails({
    ...currentConnectDetails,
    ...connectDetails,
    ...(connectDetails.gtmData || currentConnectDetails.gtmData
      ? { gtmData: [...(currentConnectDetails.gtmData || []), ...(connectDetails.gtmData || [])] }
      : {}),
  })
}

/**
 * Redirect after connection using the connectDetails cookie.
 */
export const redirect = async (connectDetails: TConnectDetails = {}): Promise<boolean> => {
  const {
    redirectUrl,
    reload,
    reloadUrl,
    socialName,
    locale = singletonRouter.locale,
    gtmData,
  } = { ...getConnectDetails(), ...connectDetails }

  // Remove connect details cookie to avoid interferences later.
  removeConnectDetails()

  /** Has already reloaded (connection on mobile device) */
  const hasReloaded = socialName && mobileDetect(window.navigator.userAgent)
  /** Should reload now */
  const shouldReload = reload || (redirectUrl?.pathname && REWRITTEN_PAGES.includes(redirectUrl.pathname))
  /** Has already reloaded or will reload now */
  const isReload = shouldReload || hasReloaded

  const url = (isReload && reloadUrl) || redirectUrl

  if (gtmData) {
    GTM.push(...gtmData)
  }

  if (shouldReload) {
    // Add a delay before redirecting to ensure request to GTM is not cancelled
    await wait(300)
    window.location.href = typeof url === 'string' ? url : translateUrl(url, locale, { format: 'string' })
    return true
  }

  if (!redirectUrl) {
    return true
  }

  return await singletonRouter.push(url, undefined, { shallow: true, locale })
}
