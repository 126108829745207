import 'regenerator-runtime/runtime'

import { removeFromQueryData } from '@hozana/api/actions'
import { fetchApiSaga } from '@hozana/api/sagas'
import { formatDateForServer, parseDay } from '@hozana/dates'
import type { TDateSlashDDMMYYYY } from '@hozana/dates/types'
import { changeForm, resetForm } from '@hozana/form/actions'
import { formatSubmissionError } from '@hozana/form/functions'
import { singletonRouter } from '@hozana/router'
import { all, put, select, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'
import { GTM_AUTH_SOURCE } from '@hozana/tracking/constants'
import { GTM } from '@hozana/tracking/gtm'

import { addFlash, displaySavingIndicator, openPopin } from 'general/actions'
import { AppActionTypes, FORM_NAMES, QUERY_ACTION } from 'general/constants'
import { POPINS } from 'general/managers/popins/constants'
import { runConfirm, runPopin } from 'general/sagas'
import { PAGE } from 'routes/constants'

import { loadToken } from 'modules/auth/functions'
import { runConnect } from 'modules/auth/sagas'
import type { TCommunityFormFormData } from 'modules/community/components/CommunityForm'
import {
  COMMUNITY_LIFETIME,
  COMMUNITY_PUBLICATION_FREQUENCY,
  COMMUNITY_ROLE,
  COMMUNITY_STATUS,
} from 'modules/community/constants'
import type { TCommunityAnnouncementFormTabFormData } from 'modules/community/pages/CommunityAnnouncementPage/CommunityAnnouncementFormTab'
import type { TAddRoleFormData } from 'modules/community/pages/CommunityRolesPage/AddRoleForm'
import { selectCommunity } from 'modules/community/selectors'
import type { TCommunity } from 'modules/community/types'
import { runWaitForFileUpload } from 'modules/upload/sagas'
import { getCommunitiesJoinedQuery, getCommunitiesWithRoleQuery, getMeQuery } from 'modules/user/queries'

import { getUserRole, isCommunityPublished, isNovenaOrRetreat } from './functions'
import {
  addCommunityRoleMutation,
  createCommunityMutation,
  deleteCommunityMutation,
  editCommunityMutation,
  getCommunityQuery,
  getCommunityRolesQuery,
  leaveCommunityMutation,
  removeCommunityRoleMutation,
  sendAnnouncementFormMutation,
} from './queries'
import {
  addCommunityRole,
  createCommunity,
  deleteCommunity,
  editCommunity,
  leaveCommunity,
  removeCommunityRole,
  sendAnnouncementFormSubmit,
  showCommunityNotifications,
} from './routines'

export type TCommunitySuccessData = { community: TCommunity }

const getEditCommunityDates = ({
  lifetime,
  ddmmyyyyFromDate,
  ddmmyyyyyToDate,
}: {
  lifetime: COMMUNITY_LIFETIME
  ddmmyyyyFromDate: TDateSlashDDMMYYYY
  ddmmyyyyyToDate: TDateSlashDDMMYYYY
}) => {
  let fromDate: Date, toDate: Date
  switch (lifetime) {
    case COMMUNITY_LIFETIME.NOVENA:
      fromDate = parseDay(ddmmyyyyFromDate)
      toDate = new Date(fromDate.getTime())
      toDate.setDate(fromDate.getDate() + 8)
      return { fromDate, toDate }

    case COMMUNITY_LIFETIME.RETREAT:
      return {
        fromDate: parseDay(ddmmyyyyFromDate),
        toDate: parseDay(ddmmyyyyyToDate),
      }

    default:
      return {
        fromDate: null,
        toDate: null,
      }
  }
}

export type TCreateCommunityPayload = TCommunityFormFormData & {
  imagesToAttach?: string[]
  imagesToRemove?: string[]
}

function* createCommunitySaga({
  payload: {
    isPrivate,
    lifetime,
    name,
    introduction,
    image,
    publicationFrequencyType,
    dayOfWeek,
    monthlyFrequency,
    prayerSampleId,
    fromDate: ddmmyyyyFromDate,
    toDate: ddmmyyyyyToDate,
    imagesToAttach = [],
  },
}: TSagaProps<TCreateCommunityPayload>) {
  try {
    const { fromDate, toDate } = getEditCommunityDates({ lifetime, ddmmyyyyFromDate, ddmmyyyyyToDate })

    image = yield* runWaitForFileUpload(image)

    const communityId = yield* fetchApiSaga(
      createCommunityMutation({
        isPrivate,
        lifetime,
        name,
        introduction,
        prayerSampleId,
        pictureFileId: image,
        publicationFrequencyType:
          lifetime === COMMUNITY_LIFETIME.NOVENA ? COMMUNITY_PUBLICATION_FREQUENCY.DAILY : publicationFrequencyType,
        publicationFrequencyValue:
          publicationFrequencyType === COMMUNITY_PUBLICATION_FREQUENCY.WEEKLY
            ? dayOfWeek
            : publicationFrequencyType === COMMUNITY_PUBLICATION_FREQUENCY.MONTHLY
            ? monthlyFrequency
            : null,
        fromDate: fromDate ? formatDateForServer(fromDate) : null,
        toDate: toDate ? formatDateForServer(toDate) : null,
        imagesToAttach,
        isDraft: true,
      }),
    )

    yield* put(changeForm(FORM_NAMES.COMMUNITY_CREATE_FORM, 'imagesToAttach', []))
    yield* put(changeForm(FORM_NAMES.COMMUNITY_CREATE_FORM, 'imagesToRemove', []))

    const newCommunity = yield* select((state) => selectCommunity(state, communityId))

    singletonRouter.push({
      pathname: PAGE.COMMUNITY_EDIT,
      query: { communityId, communitySlug: newCommunity.slug },
    })

    yield* put(createCommunity.success({ community: newCommunity }))
  } catch (error) {
    const message = error.message || error
    yield* put(createCommunity.failure(formatSubmissionError(message)))
    yield* put(displaySavingIndicator({ label: 'trans:community:draft.autosave.error', error: true }))
  }
}

export type TEditCommunityPayload = TCommunityFormFormData & {
  communityId: number
  isManualSave: boolean
  isDraft: boolean
  imagesToAttach?: string[]
  imagesToRemove?: string[]
}

function* editCommunitySaga({
  payload: {
    communityId,
    isPrivate,
    lifetime,
    name,
    introduction,
    description,
    image,
    publicationFrequencyType,
    dayOfWeek,
    monthlyFrequency,
    fromDate: ddmmyyyyFromDate,
    toDate: ddmmyyyyyToDate,
    prayerSampleId,
    prayerName,
    prayerContent,
    hasCustomPrayer,
    imagesToAttach = [],
    imagesToRemove = [],
    isManualSave,
    isDraft,
  },
}: TSagaProps<TEditCommunityPayload>) {
  try {
    const { fromDate, toDate } = getEditCommunityDates({ lifetime, ddmmyyyyFromDate, ddmmyyyyyToDate })

    const previousCommunity = yield* select((state) => selectCommunity(state, communityId))
    const updatedCommunityNovenaOrRetreat = [COMMUNITY_LIFETIME.NOVENA, COMMUNITY_LIFETIME.RETREAT].includes(lifetime)
    if (!isDraft && previousCommunity.status === COMMUNITY_STATUS.DRAFT && updatedCommunityNovenaOrRetreat) {
      const confirm = yield* runConfirm('trans:community:community.form.submit.with-dates')
      if (!confirm) {
        yield* put(editCommunity.failure('cancel'))
        throw new Error('community:common.form.error.submission.canceled')
      }
    }

    const wasDraft = previousCommunity.status === COMMUNITY_STATUS.DRAFT && !isDraft
    const novenaOrRetreat = isNovenaOrRetreat(previousCommunity) || updatedCommunityNovenaOrRetreat

    if (image && previousCommunity.pictureFileId !== image) {
      image = yield* runWaitForFileUpload(image)
    }

    yield* fetchApiSaga(
      editCommunityMutation({
        communityId,
        isPrivate,
        lifetime,
        name,
        introduction,
        description: description,
        publicationFrequencyType:
          lifetime === COMMUNITY_LIFETIME.NOVENA ? COMMUNITY_PUBLICATION_FREQUENCY.DAILY : publicationFrequencyType,
        publicationFrequencyValue:
          publicationFrequencyType === COMMUNITY_PUBLICATION_FREQUENCY.WEEKLY
            ? dayOfWeek
            : publicationFrequencyType === COMMUNITY_PUBLICATION_FREQUENCY.MONTHLY
            ? monthlyFrequency
            : null,
        fromDate: novenaOrRetreat && fromDate ? formatDateForServer(fromDate) : undefined,
        toDate: novenaOrRetreat && toDate ? formatDateForServer(toDate) : undefined,
        // Image is an ID if it has been modified
        pictureFileId: typeof image === 'number' ? image : undefined,
        prayerSampleId: hasCustomPrayer ? null : prayerSampleId,
        prayerName,
        prayerContent,
        descriptionFilesToAttach: imagesToAttach,
        descriptionFilesToRemove: imagesToRemove,
        isDraft,
      }),
    )

    yield* put(changeForm(FORM_NAMES.COMMUNITY_EDIT_FORM, 'imagesToAttach', []))
    yield* put(changeForm(FORM_NAMES.COMMUNITY_EDIT_FORM, 'imagesToRemove', []))

    const updatedCommunity = yield* select((state) => selectCommunity(state, communityId))

    if (isDraft && !isManualSave) {
      yield* put(displaySavingIndicator({ label: 'trans:community:draft.autosave.success', savedAt: new Date() }))
    }

    if (!isDraft && wasDraft && isManualSave) {
      // Refetch user communities
      const userId = yield* fetchApiSaga(getMeQuery())
      yield* fetchApiSaga(getCommunitiesWithRoleQuery({ userId }))
      yield* fetchApiSaga(getCommunitiesJoinedQuery({ userId }))

      singletonRouter.push({
        pathname: PAGE.ANIMATOR_SPACE,
      })

      yield* put(openPopin(POPINS.SubmittedCommunityPopin, { community: updatedCommunity }))
    } else if (isManualSave) {
      // Redirect to community page
      singletonRouter.push({
        pathname: isCommunityPublished(updatedCommunity) ? PAGE.COMMUNITY_VIEW : PAGE.COMMUNITY_VIEW_ADMIN,
        query: {
          communityId,
          communitySlug: updatedCommunity.slug,
        },
      })

      yield* put(addFlash({ message: 'community:community.edit.success' }))
    }

    return yield* put(editCommunity.success({ community: updatedCommunity }))
  } catch (error) {
    yield* put(displaySavingIndicator({ label: 'trans:community:draft.autosave.error', error: true }))
    return yield* put(editCommunity.failure(formatSubmissionError(error.message)))
  }
}

export type TDeleteCommunityPayload = {
  communityId: number
  userId: number
}

function* deleteCommunitySaga({ payload: { communityId, userId } }: TSagaProps<TDeleteCommunityPayload>) {
  try {
    const confirmSend = yield* runConfirm(
      'trans:community:community.delete.delete',
      'trans:community:community.delete.confirm',
    )
    if (!confirmSend) return yield* put(deleteCommunity.failure('cancel'))

    yield* fetchApiSaga(deleteCommunityMutation({ communityId }))
    // Refetch user communities
    yield* fetchApiSaga(getCommunitiesWithRoleQuery({ userId }))
    yield* fetchApiSaga(getCommunitiesJoinedQuery({ userId }))

    if (singletonRouter.pathname !== PAGE.ANIMATOR_SPACE) {
      // Redirect to profile page
      singletonRouter.push({
        pathname: PAGE.ANIMATOR_SPACE,
      })
    }

    yield* put(addFlash({ message: 'community:community.delete.success' }))
    return yield* put(deleteCommunity.success())
  } catch (error) {
    yield* put(
      addFlash({
        message: error.message,
        status: 'error',
      }),
    )

    return yield* put(deleteCommunity.failure(formatSubmissionError(error.message)))
  }
}

export type TLeaveCommunityPayload = {
  community: TCommunity
  trackingSource: string
}

function* leaveCommunitySaga({ payload: { community, trackingSource } }: TSagaProps<TLeaveCommunityPayload>) {
  GTM.trackEvent(GTM.EVENTS.COMMUNAUTE_QUITTER_CLICKED, { trackingSource })

  try {
    // Check authentication. If user cancels, cancel leave Community
    const isAuthenticated = yield* runConnect({
      authSource: GTM_AUTH_SOURCE.LEAVE_COMMUNITY,
      initialTitle: 'community:community.leave.auth-required',
      connectDetails: {
        redirectUrl: undefined,
        /**
         * After social login on mobile, on reload Hozana, redirect to community page with "?action=leave"
         * so that the leaveCommunitySaga (which has been interrupted by social connect) is retriggered to be performed
         */
        reloadUrl: {
          pathname: PAGE.COMMUNITY_VIEW,
          query: {
            communityId: community.id,
            communitySlug: community.slug,
            action: QUERY_ACTION.LEAVE,
          },
        },
      },
    })
    if (!isAuthenticated) return yield* put(leaveCommunity.failure('cancel'))

    // Get confirmation from the user
    const confirmLeave = yield* runPopin(POPINS.CommunityLeavePopin, { community: community }, AppActionTypes.CONFIRM)
    if (!confirmLeave) return yield* put(leaveCommunity.failure('cancel'))

    yield* fetchApiSaga(leaveCommunityMutation({ community }))

    GTM.trackEvent(GTM.EVENTS.COMMUNAUTE_QUITTER_DONE, { trackingSource })

    // Remove community from store
    const userId = yield* fetchApiSaga(getMeQuery())
    yield* put(removeFromQueryData(`getCommunitiesJoined/${userId}`, community.id))

    yield* put(addFlash({ message: 'community:community.leave.success' }))
    return yield* put(leaveCommunity.success({ community }))
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    return yield* put(leaveCommunity.failure(error.message))
  }
}

export type TShowCommunityNotificationsPayload = { community: TCommunity }

function* showCommunityNotificationsSaga({ payload: { community } }: TSagaProps<TShowCommunityNotificationsPayload>) {
  try {
    if (!loadToken()) {
      // Check authentication
      const isAuthenticated = yield* runConnect({
        authSource: GTM_AUTH_SOURCE.SHOW_COMMUNITY_NOTIFS,
        initialTitle: 'community:community.show-notifications.auth-required',
        connectDetails: {
          redirectUrl: undefined,
          /**
           * After social login on mobile, on reload Hozana, redirect to community page with "?action=show-notifications"
           * so that the showCommunityNotificationsSaga (which has been interrupted by social connect) is retriggered to be performed
           */
          reloadUrl: {
            pathname: PAGE.COMMUNITY_VIEW,
            query: {
              communityId: community.id,
              communitySlug: community.slug,
              action: QUERY_ACTION.SHOW_NOTIFICATIONS,
            },
          },
          reload: true,
        },
      })
      if (!isAuthenticated) return yield* put(showCommunityNotifications.failure('cancel'))

      // Get up-to-date community data
      yield* fetchApiSaga(getCommunityQuery({ communityId: community.id }))
      community = yield* select(selectCommunity, community.id)
    }

    // Ensure logged user has joined this community
    if (getUserRole(community).isMember) {
      yield* put(openPopin(POPINS.CommunityNotificationsPopin, { communityId: community.id }))
      return yield* put(showCommunityNotifications.success())
    } else {
      yield* put(addFlash({ message: 'community-not-joined', status: 'error' }))
      return yield* put(showCommunityNotifications.failure('community-not-joined'))
    }
  } catch (error) {
    yield* put(showCommunityNotifications.failure(error.message))
    throw error
  }
}

export type TSendAnnouncementFormSubmitPayload = Pick<
  TCommunityAnnouncementFormTabFormData,
  'subject' | 'message' | 'showReplyButton' | 'publishAs'
> & {
  communityIds: number[]
  isTest?: boolean
}

function* sendAnnouncementFormSubmitSaga({
  payload: { communityIds, subject, message, isTest, showReplyButton, publishAs },
}: TSagaProps<TSendAnnouncementFormSubmitPayload>) {
  try {
    if (!isTest) {
      // Get confirmation from the user
      const confirmSend = yield* runConfirm(
        'trans:community:community.announcement.send.send',
        'trans:community:community.announcement.send.confirm',
      )
      if (!confirmSend) return yield* put(sendAnnouncementFormSubmit.failure('cancel'))
    }

    yield* fetchApiSaga(
      sendAnnouncementFormMutation({ communityIds, subject, message, isTest, showReplyButton, publishAs }),
    )

    yield* put(addFlash({ message: 'community:community.announcement.form.success' }))
    if (!isTest) {
      yield* put(resetForm('sendAnnouncementForm'))
    }

    return yield* put(sendAnnouncementFormSubmit.success())
  } catch (error) {
    yield* put(
      addFlash({
        message: error.message,
        status: 'error',
      }),
    )

    return yield* put(sendAnnouncementFormSubmit.failure(formatSubmissionError(error.message)))
  }
}

function* addCommunityRoleSaga({ payload: { communityId, email, role } }: TSagaProps<TAddRoleFormData>) {
  try {
    const roleToRemove = role === COMMUNITY_ROLE.ADMINISTRATOR ? COMMUNITY_ROLE.ANIMATOR : COMMUNITY_ROLE.ADMINISTRATOR
    yield* fetchApiSaga(addCommunityRoleMutation({ communityId, email, role }))

    yield* fetchApiSaga(removeCommunityRoleMutation({ communityId, email, role: roleToRemove }))

    yield* put(
      addFlash({
        message:
          role === COMMUNITY_ROLE.ADMINISTRATOR
            ? 'community:community.role.form.add-to-administrator.success'
            : 'community:community.role.form.add-to-animator.success',
      }),
    )

    // Refetch roles
    yield* fetchApiSaga(getCommunityRolesQuery({ communityId }))

    yield* put(resetForm(FORM_NAMES.ADD_ROLE_FORM))

    yield* put(addCommunityRole.success())
  } catch (error) {
    yield* put(addCommunityRole.failure(formatSubmissionError(error.message)))
  }
}

function* removeCommunityRoleSaga({ payload: { communityId, email, role } }: TSagaProps<TAddRoleFormData>) {
  try {
    yield* fetchApiSaga(removeCommunityRoleMutation({ communityId, email, role }))

    // Refetch roles
    yield* fetchApiSaga(getCommunityRolesQuery({ communityId }))

    yield* put(removeCommunityRole.success())
    yield* put(addFlash({ message: 'community:community.role.form.remove-role.success' }))
  } catch (error) {
    yield* put(removeCommunityRole.failure(error.message))
  }
}

/*
 * LISTENER
 */

export default function* communitySagasListener() {
  yield* all([
    takeLatest(createCommunity, createCommunitySaga),
    takeLatest(editCommunity, editCommunitySaga),
    takeLatest(deleteCommunity, deleteCommunitySaga),
    takeLatest(leaveCommunity, leaveCommunitySaga),
    takeLatest(showCommunityNotifications, showCommunityNotificationsSaga),
    takeLatest(sendAnnouncementFormSubmit, sendAnnouncementFormSubmitSaga),
    takeLatest(addCommunityRole, addCommunityRoleSaga),
    takeLatest(removeCommunityRole, removeCommunityRoleSaga),
  ])
}
