import type { PLACEMENT } from '@hookform/devtools'
import dynamic from 'next/dynamic'
import React, { ForwardedRef, HTMLAttributes } from 'react'
import { Control, FormProvider, UseFormReturn } from 'react-hook-form'

import { cookie } from '@hozana/storage/cookies'

import { Div, TDivProps } from 'elements/layout/Div'

import { COOKIES } from 'general/managers/cookies/cookieNames'

/**
 * We import DevTool from @hookform/devtools/dist/index.cjs.development instead of @hookform/devtools
 * because @hookform/devtools export an empty component in production to avoid loading some more code
 * but we do that using a dynamic import activated by the presence of the debug cookie:
 * it allow us to debug in production without adding some more code for users.
 */
const DevTool = dynamic(() => import('@hookform/devtools/dist/index.cjs.development').then((m) => m.DevTool)) as <
  FormData extends TAnyProps,
>(props: {
  id?: string
  control?: Control<FormData, any>
  placement?: PLACEMENT
}) => JSX.Element | null

export type TFormProps<FormData extends TAnyProps = TAnyProps> = {
  /** Id that will identify the form in the dev tool */
  id: string
  form: UseFormReturn<FormData>
  autoComplete?: string
  noValidate?: boolean
} & Omit<TDivProps, 'onSubmit'> &
  HTMLAttributes<HTMLFormElement>

export const Form = React.forwardRef(
  <FormData extends TAnyProps>(
    { id, form, autoComplete = 'off', ...otherProps }: TFormProps<FormData>,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => (
    <FormProvider {...form}>
      <Div as="form" id={id} {...otherProps} autoComplete={autoComplete} ref={ref} />
      {cookie.load<{ form?: boolean } | undefined>(COOKIES.debugMode)?.form && (
        <DevTool control={form.control} id={id} placement="bottom-right" />
      )}
    </FormProvider>
  ),
) as TForwardRefFn<HTMLDivElement>

Form.displayName = 'Form'
