import React from 'react'

import { useAttributes } from '@hozana/dom/hooks/useAttributes'
import { useDispatch } from '@hozana/redux/hooks'
import { QA } from '@hozana/tests/constants'

import { RoundButton, TRoundButtonProps } from 'elements/button/RoundButton'

import { ICON } from 'config/icons'
import { openPopin } from 'general/actions'
import { POPINS } from 'general/managers/popins/constants'
import { TBackToPopin } from 'general/managers/popins/types'

import { SHARE_TYPES } from 'modules/share/constants'
import { useShare } from 'modules/share/hook/useShare'

export type TShareContactsProps = {
  colors?: string
  backToPopin?: TBackToPopin
} & Omit<TRoundButtonProps, 'type' | 'icon'>

export const ShareContacts: React.FC<TShareContactsProps> = ({ colors, onClick, backToPopin, ...otherProps }) => {
  const attrs = useAttributes()
  const share = useShare()
  const dispatch = useDispatch()

  const clickShare = () =>
    dispatch(
      openPopin(
        POPINS.InviteEmailPopin,
        {
          triggerButton: 'shareContacts',
          ...share,
        },
        backToPopin,
      ),
    )
  return (
    <RoundButton
      onClick={(e) => {
        onClick?.(e)
        clickShare()
      }}
      data-testid={QA.SHARE_COMMON.SOCIAL_BUTTON(SHARE_TYPES.EMAIL_ICON)}
      icon={ICON.LETTER_SOLID}
      m="3px 6px"
      label="trans:share:share.by-email"
      colors={colors}
      {...(!colors && {
        bg: 'blue',
        color: 'white',
        hoverBg: 'darkBlue',
        hoverColor: 'white',
      })}
      {...otherProps}
      {...attrs}
    />
  )
}
