/**
 * ⚠️ This file is very sensitive: please be sure to read the doc before editing it.
 * @see ./sw.readme.md
 */

import React, { useCallback, useEffect, useState } from 'react'
import { WorkboxLifecycleEvent, WorkboxMessageEvent } from 'workbox-window'

import { useAsyncEffect } from '@hozana/hooks/useAsyncEffect'
import { useEventListener } from '@hozana/hooks/useEventListener'
import { usePageVisibility } from '@hozana/hooks/usePageVisibility'
import { useDispatch } from '@hozana/redux/hooks'
import { cookie } from '@hozana/storage/cookies'
import { GTM, getGoogleAnalyticsClientId } from '@hozana/tracking/gtm'
import { Sentry } from '@hozana/tracking/sentry'

import { setNeedReload } from 'general/actions'
import { COOKIES } from 'general/managers/cookies/cookieNames'
import { SW_CONTEXT } from 'sw/constants'
import { deleteAllCaches, isForceReloadNeeded, registerServiceWorker, skipWaiting } from 'sw/functions'
import { WINDOW_MESSAGE_TYPE, swMessage } from 'sw/messages'

import { getNotificationsAvailable } from 'modules/notification/functions'
import { requestAndSaveSubscription } from 'modules/notification/routines'
import { useUser } from 'modules/user/hooks/useUser'

/**
 * ### SwManager ###
 *
 * This logic is extracted into its own component so that it can be inside `MeContext`
 * but still in _app so that it is not retriggered at each page change
 */
export const SwManager: React.FC = () => {
  const me = useUser()
  const dispatch = useDispatch()
  const pageVisible = usePageVisibility()
  const [swInitialized, setSwInitialized] = useState(false)
  const [controlled, setControlled] = useState(__CLIENT__ && !!navigator.serviceWorker?.controller)

  /**
   * Handle app reload so that the waiting service worker can activate and control fresh and compatible resources
   * - Force-reload (brutally reload the app right now) if required on deployment
   * - Auto reload (silent reload on click on a link) if the current window is the only opened Hozana window
   *
   * This will run in all windows, since all will get the waiting event
   */
  const handleWaitingSw = useCallback(async () => {
    console.debug(
      'A service worker is now waiting. Checking if a reload is needed after new service worker installation...',
    )

    // Check if force-reload is needed
    const forceReload = await isForceReloadNeeded()

    // If force-reload is needed...
    if (forceReload) {
      console.debug('Force-reload required.')
      // ... empty all caches
      await deleteAllCaches()
      // ... skip waiting
      const skipWaitingSuccess = await skipWaiting()
      if (skipWaitingSuccess) {
        // ... reload every windows
        window.location.reload()
      }
    } else {
      console.debug('No force-reload required, and only one windows: need reload activated.')
      // Otherwise, set reloadNeeded to true: reload will be performed by Link component (onClick) #silentReload
      dispatch(setNeedReload())
    }
  }, [dispatch])

  // Update push subscription
  useEffect(() => {
    if (
      swInitialized &&
      pageVisible &&
      controlled &&
      cookie.load(COOKIES.deviceID) !== undefined &&
      getNotificationsAvailable() &&
      Notification.permission === 'granted'
    ) {
      dispatch(requestAndSaveSubscription())
    }
  }, [controlled, dispatch, pageVisible, swInitialized])

  // Initialize service worker
  useAsyncEffect(
    () => [
      registerServiceWorker,
      {
        // Do not initialize service worker if:
        disabled:
          // - the service worker is already initialized
          swInitialized ||
          // - the user is not logged in: no need to bother the users with a sw if they don't come back
          !me.isLogged ||
          // - the navigator don't support service workers
          !('serviceWorker' in navigator),
        onSuccess: (registration) => {
          if (registration) {
            setSwInitialized(true)

            // Handle a waiting service worker already present on load
            if (registration.waiting) {
              handleWaitingSw()
            }
          }
        },
      },
    ],
    [dispatch, handleWaitingSw, me.isLogged, swInitialized],
  )

  // Update on document revisible
  useEffect(() => {
    if (window.workbox && pageVisible && swInitialized && navigator.serviceWorker?.controller) {
      // There is no need to check update on mount: the service worker has just been loaded?
      console.debug('Checking if a service worker update is available.')
      // This works on mount, but not well on pageVisible back to true:
      // a manual update is considered as an external update by workbox.
      // See https://github.com/GoogleChrome/workbox/issues/3119
      window.workbox
        .update()
        .then(() => console.debug('Service worker update successfully checked.'))
        .catch((error: Error) =>
          Sentry.captureException(error, {
            context: SW_CONTEXT.TRIGGER_UPDATE,
            details: 'an error occurred while checking for update on document visible again',
            rate: 0.1,
          }),
        )
    }
  }, [pageVisible, swInitialized])

  useEventListener(
    'installed',
    (e: WorkboxLifecycleEvent) => {
      console.debug(`A service worker is now installed${e.isUpdate ? ' (update)' : ''}.`)
      if (e.isUpdate) {
        GTM.trackEvent(GTM.EVENTS.PWA_UPDATE_FOUND)
      }
    },
    __CLIENT__ ? window.workbox : undefined,
    !swInitialized,
  )

  // Handle a newly installed waiting service worker
  useEventListener(
    'waiting',
    () => {
      handleWaitingSw()
    },
    __CLIENT__ ? window.workbox : undefined,
    !swInitialized,
  )

  // Send GTM event on pwa updated
  useEventListener(
    'activated',
    (e: WorkboxLifecycleEvent) => {
      console.debug(`A service worker is now activated${e.isUpdate ? ' (update)' : ''}.`)
      if (e.isUpdate) {
        GTM.trackEvent(GTM.EVENTS.PWA_UPDATED)
      }
    },
    __CLIENT__ ? window.workbox : undefined,
    !swInitialized,
  )

  // Debug log only
  useEventListener(
    'controlling',
    () => {
      setControlled(true)
      window.workbox
        .messageSW(swMessage.getVersion())
        .then((version) => console.debug('A service worker is now controlling:', version))
        .catch(undefined)
    },
    __CLIENT__ ? window.workbox : undefined,
    !swInitialized,
  )

  // Message handling
  useEventListener(
    'message',
    (event: WorkboxMessageEvent) => {
      console.debug(
        `Window received message: ${event?.data?.type}.${
          event?.data?.payload ? ` Payload: ${JSON.stringify(event.data.payload, null, 4)}` : ''
        }`,
      )
      switch (event?.data?.type) {
        // forward Google Analytics Client ID to service worker
        case WINDOW_MESSAGE_TYPE.SYN_GA_ID: {
          const googleAnalyticsClientId: string | null = getGoogleAnalyticsClientId()
          if (googleAnalyticsClientId) {
            window.workbox
              .messageSW(swMessage.sendGAId(googleAnalyticsClientId))
              .then(() => console.debug('Service worker successfully received window response.'))
              .catch(undefined)
          }
          break
        }
        case WINDOW_MESSAGE_TYPE.DISPATCH: {
          dispatch(event.data.payload)
          break
        }
        case WINDOW_MESSAGE_TYPE.RELOAD_IF_INVISIBLE: {
          if (!pageVisible) {
            window.location.reload()
          }
          break
        }
        case WINDOW_MESSAGE_TYPE.CAPTURE_EXCEPTION: {
          Sentry.captureException(event.data.payload?.error, event.data.payload?.option)
          break
        }
      }
    },
    __CLIENT__ ? window.workbox : undefined,
    !swInitialized,
  )

  return null
}
