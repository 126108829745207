import React from 'react'

import { BorderedDiv, TBorderedDivProps } from './BorderedDiv'
import { Span, TSpanProps } from './Span'

export type TBorderedSpanProps = TBorderedDivProps

export const BorderedSpan: React.FC<TBorderedSpanProps & TSpanProps> = ({ borderRadius = '3px', ...props }) => (
  <BorderedDiv as={Span} display="inline-block" borderRadius={borderRadius} {...props} />
)
