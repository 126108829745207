import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { QA } from '@hozana/tests/constants'

import { RoundButton } from 'elements/button/RoundButton'
import { Div } from 'elements/layout/Div'

import { ICON } from 'config/icons'

type TNavigationButtonProps = {
  toggleNavigation: VoidFunction
  isNavigationOpen?: boolean
}

export const NavigationButton: React.FC<TNavigationButtonProps> = ({ toggleNavigation }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const size = theme.sizes.headerItem

  return (
    <Div h={size} w={size} align="middleCenter">
      <RoundButton
        icon={ICON.BURGER}
        onClick={toggleNavigation}
        data-testid={QA.COMMON.HEADER.NAVIGATION_OPEN_BUTTON}
        aria-label={t('common:app.navigation.main')}
      />
    </Div>
  )
}
