import type { NextPage } from 'next'
import Image from 'next/image'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'elements/button/Button'
import { Div } from 'elements/layout/Div'
import { Link } from 'elements/text/Link'
import { P } from 'elements/text/P'

import { Head } from 'general/structure/Head'
import { Header } from 'general/structure/Header/Header'
import { PAGE } from 'routes/constants'

const hozanaShareImage = '/img/hozana_share.jpg'

interface Error500PageProps {
  showHeader?: boolean
}

const DRAWING_500_ERROR = '/svg/drawing_500_error.svg'

// This component in needed because it is not possible to call the Error500Page component from an ErrorBoundary.
// That is because Error500Page make the use of Page which use the AppContainer. AppContainer is initializing
// the PopinsManager. So, if for instance an error is being thrown in the PopinManager, the error would be circular.
export const Error500PageSimple: NextPage<Error500PageProps> = ({ showHeader }) => {
  const { t } = useTranslation()
  const reloadMessage = t('common:error.page.reload')
  return (
    <Div p="40px 20px 20px">
      <Head title="common:error.500.title" image={hozanaShareImage} isIndexed />
      {showHeader && (
        <div id="header">
          <Header pageTitle="trans:common:error.500.title" />
        </div>
      )}
      <Div align="center" h="150px">
        <Image src={DRAWING_500_ERROR} alt="error_500" width="150px" height="150px" />
        <P fontSize={{ xs: '1.4em', sm: '1.6em' }} bold m="0 0 20px">
          trans:common:error.500.title
        </P>
        <P fontSize={{ xs: '1em', sm: '1.4em' }} m="0 0 20px">
          <Trans i18nKey="common:error.500.message" components={{ br: <br /> }} />
        </P>
        <Link to={{ pathname: PAGE.CONTACT }}>
          <Button size="bigger" colors="yellow" m="0 0 20px 0">
            trans:common:words.contact-us
          </Button>
        </Link>

        <Button size="bigger" colors="darkBlue" m="0 0 20px 10px" onClick={() => window.location.reload()}>
          {reloadMessage.replace(/^(\w)/g, (f) => f.toUpperCase())}
        </Button>
      </Div>
    </Div>
  )
}
