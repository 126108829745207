import React from 'react'

import { ICON } from 'config/icons'
import { STATUS_TYPE } from 'general/constants'
import { PAGE } from 'routes/pages'

import { useStatus } from 'modules/notification/hooks/useStatus'

import { HeaderTab, THeaderTabProps } from './HeaderItemsComponent'

type TStatusTabProps = {
  type: STATUS_TYPE
  number?: number
} & Partial<Omit<THeaderTabProps, 'type'>>

export const StatusTab: React.FC<TStatusTabProps> = ({ type, number, ...otherProps }) => {
  const status = useStatus()

  return (
    <HeaderTab
      valueLink={{
        to: { pathname: type === STATUS_TYPE.MESSAGES ? PAGE.CONVERSATIONS : PAGE.INTENTIONS },
        reload: true,
      }}
      number={number ? number : type === STATUS_TYPE.MESSAGES && status ? status.unreadConversations : 0}
      icon={type === STATUS_TYPE.MESSAGES ? ICON.HOZANA_MESSAGE : ICON.HOZANA_INTENTION}
      {...otherProps}
    >
      {
        {
          [STATUS_TYPE.INTENTIONS]: 'trans:common:word.intentions',
          [STATUS_TYPE.MESSAGES]: 'trans:common:app.navigate.tab.messages',
        }[type]
      }
    </HeaderTab>
  )
}
