import { normalize } from 'normalizr'

import { updateEntities, updateEntity } from '@hozana/api/actions'
import { fetchApi } from '@hozana/api/fetchApi'
import type { TApiState, TQuery, TQueryConstructor } from '@hozana/api/types'
import { APP_TYPE } from '@hozana/screen/constants'
import { cookie } from '@hozana/storage/cookies'

import entities from 'config/entities'
import { COOKIES, COOKIES_CONFIG } from 'general/managers/cookies/constants'

import { updateCommunities } from 'modules/community/actions'
import type { TCommunity } from 'modules/community/types'
import { addPrayer } from 'modules/intention/actions'
import type { TNewsletterData } from 'modules/notification/components/NotificationsSettingsForm'
import { NOTIFICATION_TYPE } from 'modules/notification/constants'
import { selectNotifications, selectNotificationsByType } from 'modules/notification/selectors'
import type {
  TDevice,
  TNotification,
  TNotificationObjectType,
  TNotificationSettings,
  TNotificationType,
  TSingleNotificationSettings,
  TUserStatus,
} from 'modules/notification/types'
import { GENERAL_SETTINGS } from 'modules/user/constants'

export type TGetNotificationsQueryProps<Type extends NOTIFICATION_TYPE = NOTIFICATION_TYPE> = {
  readStatus?: 'read' | 'unread' | 'all'
  latestId?: number
  type?: Type
  objectType?: TNotificationObjectType
  objectId?: number
}

export const getNotificationsQuery = <Type extends NOTIFICATION_TYPE = NOTIFICATION_TYPE>(
  props: TGetNotificationsQueryProps<Type>,
): TQuery<number[]> => {
  const { readStatus, type, objectType, objectId, latestId } = props || {}
  const queryKeyParams = Object.entries({
    ...(readStatus ? { readStatus } : {}),
    ...(type ? { type } : {}),
    ...(objectType && objectId ? { object: objectType + '/' + objectId } : {}),
  })
    .map(([key, value]) => key + '=' + value)
    .join('&')

  return {
    /* QueryKey is composed with the prefix `getNotifications/` + the parameters that are set in the request, similarly to URL params
      Example: getNotifications/readStatus=read&type=new-comment-on-publication&objectType=community&objectId=10 */
    queryKey: 'getNotifications' + (queryKeyParams ? `/${queryKeyParams}` : ''),
    url: '/notifications',
    params: props || undefined,
    meta: { limit: 100, appendData: !!latestId, version: 2 }, // TODO: (21/07/2022): Remove version param when backward compatibility for this query is not needed anymore.
    normalize: (data: TNotification[]) => normalize(data, [entities.notification]),
    onSuccess: (dispatch, results, select) => {
      const notifications = select((state) => selectNotifications(state, results))
      notifications.forEach((notification) => {
        if (notification.type === NOTIFICATION_TYPE.NEW_PRAYER_ON_INTENTION) {
          const intentionId = notification.objectId
          const {
            prayerId,
            user: { name: userName },
          } = notification.payload
          dispatch(addPrayer(intentionId, { prayerId, userName }))
        }
      })
    },
  }
}

export const getNotificationsSettingsQuery: TQueryConstructor<
  { updateParametersToken?: string },
  TNotificationSettings
> = ({ updateParametersToken = null } = {}) => ({
  queryKey: 'getNotificationsSettings',
  url: '/me/settings/notifications',
  params: {
    updateParametersToken,
  },
})

export const getStatusQuery: TQueryConstructor<void, TUserStatus> = () => ({
  queryKey: 'getUserStatus',
  url: '/status',
  normalize: ({ notificationsCount, ...remainingData }) => ({
    result: {
      notificationsCount: typeof notificationsCount === 'string' ? JSON.parse(notificationsCount) : notificationsCount,
      ...remainingData,
    },
  }),
})

export const getPushSettingsDevicesQuery: TQueryConstructor<void, TDevice[]> = () => ({
  queryKey: 'getPushSettingsDevices',
  url: '/me/settings/devices',
})

/*
 * MUTATIONS
 */

type TMTNARMutationProps = {
  type: NOTIFICATION_TYPE
  objectType?: TNotificationObjectType
  objectId?: number
  blacklistIds?: number[]
}
export const markTypeNotificationsAsReadMutation: TQueryConstructor<TMTNARMutationProps, void> = ({
  type,
  objectType = null,
  objectId = null,
  blacklistIds = [],
}) => ({
  queryKey: 'typeNotificationsAsRead',
  url: `/notification/${type}/mark-as-read`,
  method: 'POST',
  params: { objectType, objectId, blacklistIds },
  onSuccess: (dispatch, _, select) => {
    dispatch(fetchApi(getStatusQuery()))
    const notifications = select((state) => selectNotificationsByType(state, type))
    dispatch(
      updateEntities(
        'notification',
        notifications.reduce(
          (acc, notification) => ({
            ...acc,
            [notification.id]: {
              ...notification,
              isRead: blacklistIds.includes(notification.id) ? notification.isRead : true,
            },
          }),
          {} as TApiState['entities']['notification'],
        ),
      ),
    )
  },
})

export const markNotificationAsReadMutation: TQueryConstructor<{ notificationId: number }, void> = ({
  notificationId,
}) => ({
  queryKey: `notificationAsRead/${notificationId}`,
  url: `/notification/${notificationId}/mark-as-read`,
  method: 'POST',
  // Refetch to reinitialize notifications list
  onSuccess: (dispatch) => dispatch(updateEntity('notification', notificationId, { isRead: true })),
})

export type TEditNotificationSettingMutationPayload = {
  updateParametersToken?: string
} & Partial<
  Record<TNotificationType, Record<number, TSingleNotificationSettings>> &
    Record<GENERAL_SETTINGS, TSingleNotificationSettings>
>
type TEditNotificationSettingsMutationResult = { newsletter: TNewsletterData }

export const editNotificationSettingsMutation: TQueryConstructor<
  TEditNotificationSettingMutationPayload,
  TEditNotificationSettingsMutationResult
> = ({ updateParametersToken, ...values }) => ({
  queryKey: `editNotificationsSettings`,
  url: '/me/settings/notifications' + (updateParametersToken ? `?updateParametersToken=${updateParametersToken}` : ''),
  method: 'PATCH',
  params: values,
  onSuccess: (dispatch) => {
    dispatch(fetchApi(getNotificationsSettingsQuery({ updateParametersToken })))

    if (values.publications) {
      const formattedValues = Object.entries(values.publications).reduce(
        (acc, [communityId, communitySettings]) => ({
          ...acc,
          [communityId]: {
            notifyPublicationsByEmail: communitySettings.email,
            notifyPublicationsByPush: communitySettings.push,
          },
        }),
        {},
      )
      dispatch(updateCommunities(formattedValues))
    }
    if (values.announcements) {
      const formattedValues: Record<number, Partial<TCommunity>> = Object.entries(values.announcements).reduce(
        (acc, [communityId, communityAnnouncement]) => ({
          ...acc,
          [communityId]: { notifyAnnouncements: communityAnnouncement.email },
        }),
        {},
      )
      dispatch(updateCommunities(formattedValues))
    }
  },
})

export const editCommunityNotificationsSettingsMutation: TQueryConstructor<
  {
    communityId?: number
    publicationsEmail?: boolean
    publicationsPush?: boolean
    announcementsEmail?: boolean
    updateParametersToken?: string
  },
  TEditNotificationSettingsMutationResult
> = ({ communityId, publicationsEmail, publicationsPush, announcementsEmail, updateParametersToken }) => {
  const isBoolean = (val: any) => typeof val === 'boolean'
  return editNotificationSettingsMutation({
    publications:
      isBoolean(publicationsEmail) || isBoolean(publicationsPush)
        ? {
            [communityId]: {
              email: publicationsEmail,
              push: publicationsPush,
            },
          }
        : undefined,
    announcements: isBoolean(announcementsEmail)
      ? {
          [communityId]: {
            email: announcementsEmail,
          },
        }
      : undefined,
    updateParametersToken,
  })
}

export const postDeviceMutation: TQueryConstructor<
  {
    endpoint: string
    expirationTime: number
    keys: Record<string, string>
    timezone: string
    userAgent: string
    appType: APP_TYPE
  },
  { id: string }
> = (params) => ({
  queryKey: 'postDevice',
  url: `/push/device`,
  method: 'POST',
  params,
  onSuccess: (dispatch, device) => {
    dispatch(fetchApi(getPushSettingsDevicesQuery()))
    if (device) {
      cookie.save(COOKIES.deviceID, device.id, COOKIES_CONFIG.ONE_YEAR)
    }
  },
})

export type TDeleteDeviceMutationPayload = { deviceId: string }

export const deleteDeviceMutation: TQueryConstructor<TDeleteDeviceMutationPayload, void> = ({ deviceId }) => ({
  queryKey: 'removeDevice',
  url: `/me/settings/devices/${deviceId}`,
  method: 'DELETE',
})
