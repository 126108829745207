import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'

import { AttributesProvider } from '@hozana/dom/components/AttributesProvider'
import { useDispatch } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'
import { isKindOfApp } from '@hozana/screen/functions/isKindOfApp'
import { QA } from '@hozana/tests/constants'
import { GTM_AUTH_SOURCE, UTM } from '@hozana/tracking/constants'

import { Button } from 'elements/button/Button'
import { RoundButton } from 'elements/button/RoundButton'
import { AbsoluteDiv } from 'elements/layout/AbsoluteDiv'
import { Cell } from 'elements/layout/Cell'
import { Div, TDivProps } from 'elements/layout/Div'
import { FixedDiv, TFixedDivProps } from 'elements/layout/FixedDiv'
import { Row } from 'elements/layout/Row'
import { ScrollDiv } from 'elements/layout/ScrollDiv'
import { Space } from 'elements/layout/Space'
import { Ul } from 'elements/layout/Ul'
import { P } from 'elements/text/P'
import { GradientDiv } from 'elements/ui/GradientDiv'
import { Logo } from 'elements/ui/Logo'

import { ICON } from 'config/icons'
import { openPopin } from 'general/actions'
import { FooterSocialLinks } from 'general/components/FooterSocialLinks'
import { MainLink } from 'general/components/MainLink'
import { MainLinkDonate } from 'general/components/MainLinkDonate'
import { SecondaryLink } from 'general/components/SecondaryLink'
import {
  DONATION_SOURCE,
  JOBS_PAGE_LINK,
  NAVIGATION_CLASS,
  NAVIGATION_ID,
  NOTION_ABOUT_PAGES,
  QUERY_ACTION,
} from 'general/constants'
import { POPINS } from 'general/managers/popins/constants'
import { GuidesLinkGrid } from 'general/structure/Navigation/GuidesLinkGrid'
import { PAGE } from 'routes/constants'

import { CONNECT_TAB } from 'modules/auth/constants'
import { GUIDES, GUIDE_TYPE } from 'modules/guide/constants'
import { useUser } from 'modules/user/hooks/useUser'

import { BlockLink } from './BlockLink'
import { SwitchLangButton } from './SwitchLangButton'

const animateIn = keyframes`
  from {
    transform: translate(-300px, 0);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
`

type TStyledLogoWrapperProps = {
  isOpen?: boolean
} & TDivProps

const StyledLogoWrapper = styled(Div)<TStyledLogoWrapperProps>`
  opacity: 1;
  pointer-events: none;
  ${(props) =>
    props.isOpen &&
    css`
      animation: ${animateIn} 400ms forwards;
    `};
`

type TStyledFixedDivProps = Pick<TStyledLogoWrapperProps, 'isOpen'> & TFixedDivProps

const StyledFixedDiv = styled(FixedDiv)<TStyledFixedDivProps>`
  overflow: auto;
  transform: translate3d(${(props) => (props.isOpen ? '0' : '-100%')}, 0, 0);
  transition: transform 0.3s;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);
`

type TNavigationProps = {
  toggleNavigation: VoidFunction
} & Pick<TStyledLogoWrapperProps, 'isOpen'>

export const Navigation: React.FC<TNavigationProps> = ({ isOpen, toggleNavigation }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const dispatch = useDispatch()
  const me = useUser()
  const { pathname } = useRouter()

  const redirectUrlConnectDetail = pathname === PAGE.HOME ? { redirectUrl: { pathname: PAGE.USER_FEED } } : {}
  const hasMailDeliveryIssue =
    me.mailDeliveryIssues?.invalid || me.mailDeliveryIssues?.spamReport || me.mailDeliveryIssues?.hardBounce

  const createOnClickConnect =
    (tab: CONNECT_TAB): MouseEventHandler<HTMLButtonElement> =>
    () => {
      toggleNavigation()
      dispatch(
        openPopin(POPINS.ConnectPopin, {
          authSource: tab === CONNECT_TAB.LOGIN ? GTM_AUTH_SOURCE.NAVIGATION_LOGIN : GTM_AUTH_SOURCE.NAVIGATION_SIGNUP,
          connectDetails: { displayWizardOnSignup: true, ...redirectUrlConnectDetail },
        }),
      )
    }

  return (
    <AttributesProvider attributes={isOpen ? null : { tabIndex: -1 }}>
      <StyledFixedDiv
        isOpen={isOpen}
        top
        bottom
        left
        w={{ xs: 1, sm: '350px' }}
        bg="white"
        maxH="100%"
        maxW="100%"
        noPrint
        zIndex="menu"
      >
        <Div
          as="nav"
          data-testid={QA.COMMON.NAVIGATION.NAVIGATION_PANEL}
          h="100%"
          aria-label={t('common:app.navigation.secondary')}
          id={NAVIGATION_ID}
          role="navigation"
        >
          <ScrollDiv scrollbarWidth="12px" p="0 1em 50px 1em" className={NAVIGATION_CLASS}>
            <Space h="10px" />
            <StyledLogoWrapper visible="xs" align="middleLeft" w="auto" h="50px" isOpen={isOpen} m="0 0 1em 0">
              <Logo full color="black30" fill="black30" />
            </StyledLogoWrapper>
            <StyledLogoWrapper invisible="xs" align="middleLeft" w="auto" h="50px" isOpen={isOpen} m="0 0 1em 0">
              <Logo full color="black30" fill="black30" />
            </StyledLogoWrapper>
            {me.isLogged && me.id ? (
              <>
                {/* WIZARD */}
                <GradientDiv firstColor="darkOrange" lastColor="lightYellow" borderRadius="10px">
                  <BlockLink to={{ pathname: PAGE.WIZARD }} icon={ICON.FIRE} bg="transparent" hoverBg="orange">
                    trans:common:user.wizard.my-wizard
                  </BlockLink>
                </GradientDiv>
                <Space />

                <Ul>
                  <MainLink
                    data-testid={QA.COMMON.NAVIGATION.PROFILE_LINK}
                    icon={ICON.PROFIL}
                    to="/profile"
                    reload
                    onClick={toggleNavigation}
                  >
                    trans:common:user.profile.my-profile
                  </MainLink>
                  {me.communitiesCount > 0 && (
                    <MainLink icon={ICON.USER_EDIT} to={{ pathname: PAGE.ANIMATOR_SPACE }} onClick={toggleNavigation}>
                      trans:common:community.toolbar.title
                    </MainLink>
                  )}
                  <MainLink
                    data-testid={QA.COMMON.NAVIGATION.FAVORITES_LINK}
                    icon={ICON.BOOKMARK_OFF}
                    to={{ pathname: PAGE.PUBLICATION_FAVORITES }}
                    onClick={toggleNavigation}
                  >
                    trans:common:publication.favorites
                  </MainLink>
                  {language === 'fr' && (
                    <MainLink
                      to={{ pathname: PAGE.GUIDE, query: { guideName: GUIDES.fr[GUIDE_TYPE.LIVE] } }}
                      data-testid={QA.COMMON.NAVIGATION.LIVE_LINK}
                      icon={ICON.LIVE}
                      onClick={toggleNavigation}
                    >
                      trans:common:navigation.live-page-link
                    </MainLink>
                  )}
                  <MainLink
                    data-testid={QA.COMMON.NAVIGATION.SETTINGS_LINK}
                    icon={ICON.SETTINGS}
                    to={{ pathname: PAGE.SETTINGS }}
                    onClick={toggleNavigation}
                    reload
                    statusNumber={hasMailDeliveryIssue ? 1 : 0}
                  >
                    trans:common:user.parameters
                  </MainLink>
                </Ul>
              </>
            ) : (
              <>
                {/* SIGNIN / SIGNUP */}
                <Space />
                <Row gutter="10px">
                  <Cell flex="1">
                    <Button
                      w="100%"
                      data-testid={QA.COMMON.NAVIGATION.SIGNUP_LINK}
                      reverseColors
                      onClick={createOnClickConnect(CONNECT_TAB.SIGNUP)}
                    >
                      trans:common:auth.signup.signup
                    </Button>
                  </Cell>
                  <Cell>
                    <Button
                      w="100%"
                      data-testid={QA.COMMON.NAVIGATION.LOGIN_LINK}
                      onClick={createOnClickConnect(CONNECT_TAB.LOGIN)}
                    >
                      trans:common:auth.login.login
                    </Button>
                  </Cell>
                </Row>
                <Space />

                {/* COMMUNITIES, INTENTIONS, LIVE */}
                <Ul>
                  <MainLink
                    asButton
                    data-testid={QA.COMMON.NAVIGATION.COMMUNITIES_LINK}
                    icon={ICON.COMMUNITY}
                    to={{ pathname: PAGE.COMMUNITY_LIST }}
                    reload
                    onClick={toggleNavigation}
                  >
                    trans:common:community.communities.discover
                  </MainLink>
                  <MainLink icon={ICON.INTENTION} to={{ pathname: PAGE.INTENTIONS }} reload onClick={toggleNavigation}>
                    trans:common:navigation.intentions
                  </MainLink>
                  {language === 'fr' && (
                    <MainLink
                      to={{ pathname: PAGE.GUIDE, query: { guideName: GUIDES.fr[GUIDE_TYPE.LIVE] } }}
                      data-testid={QA.COMMON.NAVIGATION.LIVE_LINK}
                      icon={ICON.LIVE}
                      onClick={toggleNavigation}
                    >
                      trans:common:navigation.live-page-link
                    </MainLink>
                  )}
                </Ul>
              </>
            )}
            {/* APPLI */}
            <Div m="1em 0">
              {!isKindOfApp() ? (
                <GradientDiv firstColor="darkOrange" lastColor="lightYellow" borderRadius="10px">
                  <BlockLink
                    data-testid={QA.COMMON.NAVIGATION.APP_STORE_LINK}
                    to={{ pathname: PAGE.APP_LANDING_PAGE, query: { utm_medium: UTM.MEDIUM.APP_MENU_LINK } }}
                    icon={ICON.MOBILE}
                    bg="transparent"
                    hoverBg="orange"
                    onClick={toggleNavigation}
                  >
                    trans:common:home.welcome.app-section.title
                  </BlockLink>
                </GradientDiv>
              ) : (
                <BlockLink
                  to={{ pathname: PAGE.USER_FEED, query: { action: QUERY_ACTION.INVITE } }}
                  icon={ICON.SEND}
                  bg="darkBlue"
                  hoverBg="blue"
                  onClick={toggleNavigation}
                >
                  trans:common:share.invite-friends.long
                </BlockLink>
              )}
            </Div>
            {/* FAQ, JOBS, DONATE */}
            <Ul>
              {me.lang === 'fr' && (
                <MainLink
                  icon={ICON.EVENT}
                  to={{ pathname: PAGE.STATIC, query: { pageSlug: 'evenements' } }}
                  onClick={toggleNavigation}
                >
                  trans:common:app.navigation.events-link
                </MainLink>
              )}
              {me.lang === 'fr' && (
                <MainLink icon={ICON.TESTIMONY} to={{ pathname: PAGE.TESTIMONIES }} onClick={toggleNavigation}>
                  trans:common:app.navigation.testimonies-link
                </MainLink>
              )}
              {me.lang === 'fr' && (
                <MainLink icon={ICON.JOBS} to={JOBS_PAGE_LINK} onClick={toggleNavigation}>
                  trans:common:app.navigation.jobs-link
                </MainLink>
              )}
              <MainLink
                icon={ICON.HELP_OFF}
                data-testid={QA.COMMON.NAVIGATION.MISC_LINKS(PAGE.FAQ)}
                to={{ pathname: PAGE.FAQ }}
                onClick={toggleNavigation}
              >
                trans:common:app.navigation.footer.faq.link
              </MainLink>
              <MainLinkDonate source={DONATION_SOURCE.MENU} data-testid={QA.COMMON.NAVIGATION.DONATE_LINK} />
            </Ul>
            {/* GUIDES */}
            <Space />
            <P align="center" color="black50" m="0 0 10px 0">
              trans:common:navigation.guides.title
            </P>
            <GuidesLinkGrid onClick={toggleNavigation} />
            <Space />
            {!me.isLogged && <SwitchLangButton />}
            {/* SECONDARY LINKS */}
            <Ul>
              <SecondaryLink
                data-testid={QA.COMMON.NAVIGATION.MISC_LINKS(PAGE.CONTACT)}
                to={{ pathname: PAGE.CONTACT }}
              >
                trans:common:words.contact-us
              </SecondaryLink>
              <SecondaryLink
                data-testid={QA.COMMON.NAVIGATION.MISC_LINKS('introduction')}
                to={{ pathname: PAGE.STATIC, query: { pageSlug: 'introduction' } }}
              >
                trans:common:home.faq.title.head
              </SecondaryLink>
              <SecondaryLink data-testid={QA.COMMON.NAVIGATION.MISC_LINKS('about')} to={NOTION_ABOUT_PAGES[language]}>
                trans:common:app.navigation.footer.who.we.are.link
              </SecondaryLink>
              <SecondaryLink
                data-testid={QA.COMMON.NAVIGATION.MISC_LINKS(PAGE.COMMUNITY_LIST_SEO)}
                to={{ pathname: PAGE.COMMUNITY_LIST_SEO }}
              >
                trans:common:community.all
              </SecondaryLink>

              <SecondaryLink to={{ pathname: PAGE.GUIDES_LIST }}>
                trans:common:app.navigation.footer.guide.all
              </SecondaryLink>

              <SecondaryLink
                data-testid={QA.COMMON.NAVIGATION.MISC_LINKS('cgu')}
                to={{ pathname: PAGE.STATIC, query: { pageSlug: 'cgu' } }}
              >
                trans:common:app.navigation.footer.general.terms.use.link
              </SecondaryLink>
            </Ul>
            <FooterSocialLinks />
            <AbsoluteDiv right="7px" top="7px">
              <RoundButton
                icon={ICON.CLOSE}
                onClick={toggleNavigation}
                color="black30"
                hoverColor="black50"
                size="2em"
                label="trans:common:word.close"
                ariaLabel="trans:common:app.navigation.close"
                tabIndex={isOpen ? null : -1}
                data-testid={QA.COMMON.NAVIGATION.CLOSE_BUTTON}
              />
            </AbsoluteDiv>
          </ScrollDiv>
        </Div>
      </StyledFixedDiv>
    </AttributesProvider>
  )
}
