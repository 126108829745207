import 'regenerator-runtime/runtime'

import { translateUrl } from 'next-translate-routes'

import { fetchApiSaga } from '@hozana/api/sagas'
import { openPopup } from '@hozana/dom/functions'
import { formatSubmissionError } from '@hozana/form/functions'
import { singletonRouter } from '@hozana/router'
import { runRoutine } from '@hozana/routines/runRoutine'
import { all, call, delay, put, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'
import { getAppType } from '@hozana/screen/functions/getAppType'
import { mobileDetect } from '@hozana/screen/functions/mobileDetect'
import { cookie } from '@hozana/storage/cookies'
import { GTM } from '@hozana/tracking/gtm'
import { getLandingInfo } from '@hozana/tracking/landingInfo'
import { Sentry } from '@hozana/tracking/sentry'
import { getSessionInfo } from '@hozana/tracking/sessionInfo'
import { clearSponsorInfos, getSponsorInfos } from '@hozana/tracking/sponsor'

import { acceptCookies, addFlash, closeNavigation, closePopin } from 'general/actions'
import { QUERY_ACTION } from 'general/constants'
import { getConnectDetails, updateConnectDetails } from 'general/functions'
import { COOKIES, COOKIES_CONFIG } from 'general/managers/cookies/constants'
import { POPINS } from 'general/managers/popins/constants'
import { runPopin, runRedirect } from 'general/sagas'
import { PAGE } from 'routes/constants'
import { deleteAllCaches } from 'sw/functions'

import type { TConnectPopinOptions } from 'modules/auth/popins/ConnectPopin'
import type { TFacebookConfirmPopinFormData } from 'modules/auth/popins/FacebookConfirmPopin'
import { WIZARD_CHAPTER } from 'modules/user/constants'
import { editAccountSettingsMutation, getMeQuery } from 'modules/user/queries'

import { loadToken, removeToken, saveTokenCookie } from './functions'
import {
  TConnectMutationData,
  TGetLoginMethodsPayload,
  TLogEmailMutationPayload,
  TLoginMutationPayload,
  TRequestPasswordRecoveryMutationPayload,
  TResetPasswordMutationPayload,
  TSignupMutationPayload,
  checkAuthCodeDoubleFaFetch,
  getLoginMethodsQuery,
  getRefreshTokenQuery,
  logEmailMutation,
  loginMutation,
  requestPasswordRecoveryMutation,
  resetPasswordMutation,
  signupMutation,
} from './queries'
import {
  doubleFaFormSubmit,
  facebookConfirmFormSubmit,
  getLoginMethods,
  logEmail,
  loginFormSubmit,
  logout,
  lostPwdFormSubmit,
  refreshToken,
  resetPwdFormSubmit,
  saveToken,
  signupFormSubmit,
  socialLogin,
} from './routines'

require('es6-promise').polyfill()

export type TSaveTokenPayload = { token: string }

function* saveTokenSaga({ payload: { token } }: TSagaProps<TSaveTokenPayload>) {
  try {
    saveTokenCookie(token)
    yield* put(saveToken.success())
    cookie.save(COOKIES.hasAlreadyLogged, true, COOKIES_CONFIG.ONE_YEAR)
    yield* put(acceptCookies())
    GTM.trackLogin()
    yield* put(closeNavigation())
    yield* fetchApiSaga(getMeQuery())
  } catch (error) {
    yield* put(saveToken.failure(error.message))
  }
}

export type TSocialLoginPayload = {
  url: string
}

function* socialLoginSaga({ payload: { url } }: TSagaProps<TSocialLoginPayload>) {
  try {
    let popupWindow: Window, token: string

    if (mobileDetect(window.navigator.userAgent)) {
      yield* delay(100)

      // Redirect to FB/Google, and then to SocialAuthPage
      // SocialAuthPage handle the rest through redirection
      window.location.href = url
    } else {
      // Open popup to FB/Google and wait for users to have logged in
      popupWindow = openPopup(800, 600, url)

      // Once logged in, SocialAuthPage replace FB/Google in the popup while the token is not loaded
      do {
        yield* delay(2000)
        token = loadToken()
      } while (!token)

      if (popupWindow && !popupWindow.closed) {
        popupWindow.close()
      }

      // SocialAuthPage stored the information in a cookie if a new user comes from facebook
      const { email, socialName, newUser } = getConnectDetails()

      // We wait facebookConfirmForm to be submitted before continuing.
      if (newUser && socialName === 'facebook') {
        yield* runPopin(POPINS.FacebookConfirmPopin, { email }, facebookConfirmFormSubmit.SUCCESS)
      }

      // Token is alreay saved but we need the other commands executed in the saveTokenSaga
      yield* runRoutine(saveToken, { token })

      yield* runRedirect()

      // This must be done after login.success
      yield* put(closePopin(POPINS.ConnectPopin))

      yield* put(socialLogin.success())
    }
  } catch (error) {
    yield* put(socialLogin.failure(error.message))
  }
}

function* facebookConfirmFormSubmitSaga({ payload: { email, prevEmail } }: TSagaProps<TFacebookConfirmPopinFormData>) {
  try {
    // Update email if it has been changed
    if (prevEmail !== email) {
      yield* fetchApiSaga(editAccountSettingsMutation({ email }))
    }

    yield* put(closePopin(POPINS.FacebookConfirmPopin))

    // Refetch user information with email
    yield* fetchApiSaga(getMeQuery())

    yield* runRedirect()

    yield* put(facebookConfirmFormSubmit.success())
  } catch (error) {
    yield* put(facebookConfirmFormSubmit.failure(formatSubmissionError(error.message)))
  }
}

export type T2FaPayload = { code: string; method: 'email' | 'totp' }

function* doubleFaFormSubmitSaga({ payload: { code, method } }: TSagaProps<T2FaPayload>) {
  const fetch2Fa = async () => {
    const response = await checkAuthCodeDoubleFaFetch({ code, method })
    if (response.status === 200) {
      const { data } = (await response.json()) as { data: TConnectMutationData }
      return data
    }
    throw new Error('auth:login.2fa.failed')
  }

  try {
    const response = yield* call(fetch2Fa)
    yield* put(doubleFaFormSubmit.success(response))
  } catch (error) {
    yield* put(doubleFaFormSubmit.failure(formatSubmissionError(error.message)))
  }
}

export type TLogoutPayload = { isDeleted?: boolean }

function* logoutSaga({ payload: { isDeleted } }: TSagaProps<TLogoutPayload>) {
  try {
    GTM.trackLogout(true)

    yield* delay(1000)

    try {
      yield* call(deleteAllCaches)
    } catch {
      /* Empty */
    }

    removeToken()

    // If we use singletonRouter here, the page is broken
    // TODO: use singletonRouter.push then fix the HomePage
    const newUrl = translateUrl(
      {
        pathname: isDeleted ? PAGE.LEAVE : PAGE.HOME,
        query: !isDeleted && {
          action: QUERY_ACTION.SHOW_FLASH,
          message: 'auth:user.logout.success',
        },
      },
      singletonRouter.locale,
      { format: 'string' },
    )
    window.location.href = newUrl

    yield* put(logout.success())
  } catch (error) {
    yield* put(logout.failure(error.message))
  }
}

export type TRefreshTokenPayload = { oldToken: string }
export type TRefreshTokenResult = { token: string }

function* refreshTokenSaga({ payload: { oldToken } }: TSagaProps<TRefreshTokenPayload>) {
  try {
    const data = yield* fetchApiSaga(getRefreshTokenQuery())

    saveTokenCookie(data.token)

    // Log refreshToken event
    console.log('Token refreshed !', data)
    Sentry.captureMessage('token-refreshed', 'warning', {
      newToken: data.token,
      oldToken,
    })

    yield* put(refreshToken.success(data))
  } catch (error) {
    yield* put(refreshToken.failure(error.message))
  }
}

export function* loginSaga({ payload: { email, password } }: TSagaProps<TLoginMutationPayload>) {
  let data

  try {
    data = yield* fetchApiSaga(
      loginMutation({
        email,
        password,
        method: null,
      }),
    )
  } catch (loginError) {
    if (!(loginError instanceof Error) || !loginError.message.endsWith('login.failed')) {
      Sentry.captureMessage('Login error in loginSaga', 'debug', {
        hasEmail: !!email,
        hasPassword: !!password,
        message: loginError instanceof Error ? loginError.message : loginError,
      })
    }
    throw loginError
  }

  if (data['2faCompleted'] === false && process.env.ENV !== 'dev') {
    data = (yield* runPopin(POPINS.DoubleFaPopin, { email, password, method: data.method }, doubleFaFormSubmit.SUCCESS))
      ?.payload
  }

  yield* runRoutine(saveToken, { token: data.token })

  yield* runRedirect()

  // Used by joinCommunitySaga
  cookie.save(COOKIES.connectMethod, 'Login', COOKIES_CONFIG.SESSION)

  return data
}

function* loginFormSubmitSaga({ payload }: TSagaProps<TLoginMutationPayload>) {
  try {
    const data = yield* loginSaga({ payload })
    yield* put(loginFormSubmit.success(data))
    return data
  } catch (error) {
    yield* put(loginFormSubmit.failure(formatSubmissionError(error.message)))
    return null
  }
}

export type TSignupFormSubmitPayload = Pick<TSignupMutationPayload, 'email' | 'password' | 'name' | 'captcha'>

export function* signupSaga({ payload: { email, password, name, captcha } }: TSagaProps<TSignupFormSubmitPayload>) {
  let data: TConnectMutationData = undefined

  try {
    const landingInfo = getLandingInfo()

    if (!landingInfo?.landingDate && !landingInfo?.landingUrl && !landingInfo?.referrer) {
      Sentry.captureMessage('All landing info missing', 'debug', {
        appType: getAppType(),
        sessionInfo: getSessionInfo(),
      })
    }

    data = yield* fetchApiSaga(
      signupMutation({
        email,
        password,
        name,
        captcha,
        sponsorKey: getSponsorInfos(),
        ...getLandingInfo(),
      }),
    )

    // Used by joinCommunitySaga
    cookie.save(COOKIES.connectMethod, 'Signup', COOKIES_CONFIG.SESSION)

    clearSponsorInfos()

    yield* put(closeNavigation())

    yield* runRoutine(saveToken, { token: data.token })

    yield* runRedirect()
  } catch (signupError) {
    if (signupError?.message.endsWith('signup.email.exists')) {
      try {
        data = yield* loginSaga({ payload: { email, password, method: null } })
      } catch (loginError) {
        if (loginError.message?.endsWith('auth.login.failed')) {
          // Since we know the email is correct, we assume that the 'auth:login.failed' error comes from a wrong password
          throw new Error('auth:password.invalid', loginError)
        } else {
          throw signupError
        }
      }
    }
    if (data) {
      cookie.save(COOKIES.connectMethod, 'Login', COOKIES_CONFIG.SESSION)
    } else {
      throw new Error(signupError.message)
    }
  }

  return data
}

function* signupFormSubmitSaga({ payload }: TSagaProps<TSignupFormSubmitPayload>) {
  try {
    // Display the wizard on signup if it should be displayed. See SocialAuthPage wizardUrl for social flow.
    if (getConnectDetails().displayWizardOnSignup) {
      updateConnectDetails({
        redirectUrl: {
          pathname: PAGE.WIZARD_CHAPTER,
          query: { chapter: WIZARD_CHAPTER.WELCOME },
        },
      })
    }
    const data = yield* signupSaga({ payload })
    yield* put(signupFormSubmit.success(data))
  } catch (error) {
    yield* put(signupFormSubmit.failure(formatSubmissionError(error.message)))
  }
}

function* lostPwdFormSubmitSaga({ payload }: TSagaProps<TRequestPasswordRecoveryMutationPayload>) {
  try {
    GTM.trackEvent(GTM.EVENTS.LOST_PASSWORD_FINISHED)
    const data = yield* fetchApiSaga(requestPasswordRecoveryMutation(payload))
    yield* put(lostPwdFormSubmit.success(data))
  } catch (error) {
    yield* put(lostPwdFormSubmit.failure(formatSubmissionError(`common:${error.message}`)))
  }
}

function* resetPwdFormSubmitSaga({ payload }: TSagaProps<TResetPasswordMutationPayload>) {
  try {
    const data = yield* fetchApiSaga(resetPasswordMutation(payload))

    if (!data.token) {
      yield* put(resetPwdFormSubmit.failure(formatSubmissionError('auth:token.invalid')))
      return
    }

    // Used by joinCommunitySaga
    cookie.save(COOKIES.connectMethod, 'Reset-Password', COOKIES_CONFIG.SESSION)

    yield* runRoutine(saveToken, { token: data.token })

    GTM.trackEvent(GTM.EVENTS.RESET_PASSWORD_SUCCESS)

    const { redirectUrl } = getConnectDetails()

    yield* runRedirect({ redirectUrl: { pathname: PAGE.USER_FEED, ...redirectUrl } })

    yield* put(addFlash({ message: 'auth:auth.recover.success' }))
    yield* put(resetPwdFormSubmit.success(data))
  } catch (error) {
    yield* put(resetPwdFormSubmit.failure(formatSubmissionError(error.message)))
  }
}

export type TLogEmailPayload = Pick<TLogEmailMutationPayload, 'email'> & {
  communityId: TLogEmailMutationPayload['interestedByCommunity']
}

function* logEmailSaga({ payload: { email, communityId } }: TSagaProps<TLogEmailPayload>) {
  try {
    yield* fetchApiSaga(
      logEmailMutation({
        email,
        interestedByCommunity: communityId,
        sponsorKey: getSponsorInfos(),
        ...getLandingInfo(),
      }),
    )
    yield* put(logEmail.success())
  } catch (error) {
    yield* put(logEmail.failure(error.message))
  }
}

function* getLoginMethodsSaga({ payload: { email } }: TSagaProps<TGetLoginMethodsPayload>) {
  try {
    const loginMethods = yield* fetchApiSaga(getLoginMethodsQuery({ email }))
    yield* put(getLoginMethods.success(loginMethods))
  } catch (error) {
    yield* put(getLoginMethods.failure(error.message))
  }
}

/*
 * LISTENER
 */

export default function* authSagasListener() {
  yield* all([
    takeLatest(saveToken, saveTokenSaga),
    takeLatest(logout, logoutSaga),
    takeLatest(refreshToken, refreshTokenSaga),
    takeLatest(loginFormSubmit, loginFormSubmitSaga),
    takeLatest(signupFormSubmit, signupFormSubmitSaga),
    takeLatest(lostPwdFormSubmit, lostPwdFormSubmitSaga),
    takeLatest(resetPwdFormSubmit, resetPwdFormSubmitSaga),
    takeLatest(logEmail, logEmailSaga),
    takeLatest(getLoginMethods, getLoginMethodsSaga),
    takeLatest(socialLogin, socialLoginSaga),
    takeLatest(facebookConfirmFormSubmit, facebookConfirmFormSubmitSaga),
    takeLatest(doubleFaFormSubmit, doubleFaFormSubmitSaga),
  ])
}

/**
 * UTILS
 */

export function* runConnect(popinOptions: TConnectPopinOptions & { noEscape?: boolean }): Generator<any, boolean> {
  // If user is already connected, return immediately
  const token = loadToken()
  if (token) return true

  // Open the popin and wait for the login success
  return !!(yield* runPopin(POPINS.ConnectPopin, popinOptions, saveToken.SUCCESS))
}
