import 'regenerator-runtime/runtime'

import { fetchApiSaga } from '@hozana/api/sagas'
import { formatSubmissionError } from '@hozana/form/functions'
import { all, put, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'

import { addFlash } from 'general/actions'

import { TSendContactFormMutationPayload, sendContactFormMutation } from './queries'
import { contactFormSubmit } from './routines'

function* contactFormSubmitSaga({
  payload: { email, firstname, lastname, subject, message, captcha },
}: TSagaProps<TSendContactFormMutationPayload>) {
  try {
    yield* fetchApiSaga(sendContactFormMutation({ email, firstname, lastname, subject, message, captcha }))
    yield* put(addFlash({ message: 'home:home.contact.form.success' }))

    yield* put(contactFormSubmit.success())
  } catch (error) {
    yield* put(
      addFlash({
        message: error.message,
        status: 'error',
      }),
    )

    yield* put(contactFormSubmit.failure(formatSubmissionError(error.message)))
  }
}

/*
 * LISTENER
 */

export default function* homeSagasListener() {
  yield* all([takeLatest(contactFormSubmit, contactFormSubmitSaga)])
}
