import type { Action } from 'redux'
import type { ThunkAction } from 'redux-thunk'

import { detectLangFromPath } from '@hozana/intl/functions'
import { getFirstQueryValue, getQueryValues } from '@hozana/router/functions'
import { cookie } from '@hozana/storage/cookies'
import { sanitize } from '@hozana/utils/functions/objects'
import { createUUID } from '@hozana/utils/functions/strings'

import type { TState } from 'config/types'
import { COOKIES } from 'general/managers/cookies/constants'
import { E2E_QUERY_KEY } from 'general/managers/debugger/constants'

import { loadToken } from 'modules/auth/functions'

import { ApiError } from './ApiError'
import {
  fetchApiFailure,
  fetchApiSuccess,
  fetchApiTrigger,
  maintenanceActivate,
  maintenanceDeactivate,
} from './actions'
import { TQueryStringParam, buildQueryString, checkApiErrors, fetchHeaders, getApiUrl, getQueryKey } from './functions'
import { selectMaintenanceStatus } from './selectors'
import type { TApiState, TQuery, TQueryData } from './types'

/**
 * ### fetchApi ###
 *
 * Any api fetching should use fetchApi. It creates a Redux-thunk, that must be dispatched.
 *
 * Exemple:
 *
 * ```js
 * dispatch(fetchApi(getCommunityQuery({ communityId: 18 })))
 * ```
 *
 * @param query [TQuery object](types.ts)
 * @param throwError By default errors are not thrown, so if you want to catch them in the promise,
 * you need to set the second parameter of `fetchApi` to true.
 *
 * ```js
 * dispatch(fetchApi(addCommentMutation({ title, content, files }), true))
 *   .then(commentId => console.log(commentId))
 *   .catch(error => console.error(commentId))
 * ```
 *
 * @param e2eDB Set this to true to fetch using the E2E database.
 * @returns a Redux-thunk promise: you can chain it if you need to have the result directly on your function.
 *
 * ```js
 * dispatch(fetchApi(addCommentMutation({ title, content, files })))
 *   .then(commentId => console.log(commentId))
 * ```
 */
export const fetchApi =
  <Query extends TQuery>(
    {
      queryKey,
      method = 'GET',
      url,
      headers: queryHeaders = {},
      params = {},
      file = null,
      meta = {},
      normalize = null,
      onSuccess = null,
      credentials = 'omit',
    }: Query,
    throwError = false,
    e2eDB?: boolean,
  ): ThunkAction<Promise<TQueryData<Query>>, TState, void, Action<string>> =>
  async (dispatch, getState) => {
    const key = getQueryKey({ queryKey, url, params })
    const allParams = sanitize(params)
    if (meta.limit) allParams.limit = meta.limit
    if (meta.version) allParams.version = meta.version
    if (!meta.lang) {
      if (__SERVER__) throw new Error('SSR call to fetchApi does not include meta.lang for query ' + key)
      meta.lang = detectLangFromPath(`/${window.location.href.split('/').slice(3).join('/')}`)
    }

    // When we are refetching, we want to start with a 0 offset
    // But we don't store this 0 offset in redux so that fetchMore still works
    if (meta.refetching) {
      allParams.offset = 0
    } else if (meta.offset) {
      allParams.offset = meta.offset
    }

    // Bypass CloudFlare cache when building static pages to get fresh data
    // This random parameters creates a new route that CloudFlare does not have in cache
    if (meta.ssgFetched) {
      allParams.bypassCFCache = createUUID().slice(0, 8)
    }
    const headers = fetchHeaders({
      method,
      additionalHeaders: queryHeaders,
      isUpload: !!file,
      // Disable authentication while on SSG to avoid building user-specific pages while revalidating
      token: meta.ssgFetched ? null : loadToken(),
    })
    const apiUrl = getApiUrl(meta.lang, headers['Cache-Control' as keyof HeadersInit] === 'public')
    const baseUrl = url.includes('http') ? url : apiUrl + url
    const path =
      method === 'GET' && allParams
        ? baseUrl + '?' + buildQueryString(allParams as Record<string, TQueryStringParam>)
        : baseUrl

    dispatch(fetchApiTrigger(key, method, url, params, meta))

    const clientQuery = __CLIENT__ ? getQueryValues(window.location) : undefined

    if (
      e2eDB ||
      cookie.load(COOKIES.e2eDB) ||
      (clientQuery && getFirstQueryValue(clientQuery[E2E_QUERY_KEY]) === 'true')
    ) {
      headers['X-E2e-Enabled' as keyof HeadersInit] = 'true'
    }

    try {
      let fetchOptions: RequestInit

      if (file) {
        const formData = new FormData()
        formData.append('file', file)

        Object.keys(allParams).forEach((key) => {
          formData.append(key, params[key] as string | Blob)
        })

        fetchOptions = {
          headers,
          body: formData,
          method: 'POST',
          redirect: 'follow',
        }
      } else {
        fetchOptions = {
          body: method !== 'GET' ? JSON.stringify(allParams) : undefined,
          headers,
          method,
          redirect: 'follow',
          credentials,
        }
      }

      const rawResponse = await fetch(path, fetchOptions)
      const checkedResponse = await checkApiErrors(rawResponse)
      const parsedResponse =
        checkedResponse.status === 204 ? { data: { success: true, hasContent: false } } : await checkedResponse.json()

      const { entities, result } = (
        normalize ? normalize(parsedResponse.data) : { entities: null, result: parsedResponse.data }
      ) as { entities: TApiState['entities']; result: TQueryData<Query> }
      dispatch(fetchApiSuccess(key, entities, result, { ...meta, ...parsedResponse.meta }))

      if (selectMaintenanceStatus(getState())) {
        // We are in maintenance. Query is successfull, so we deactivate it
        dispatch(maintenanceDeactivate())
      }

      if (onSuccess) onSuccess(dispatch, result, (selector) => selector(getState()))

      // Return result in case we want to use fetchApi().then()
      return result
    } catch (err) {
      let error = Object.assign(new Error(err.message), { ...err }) // Only way I found to duplicate the error in order not to mutate the original error

      // When in maintenance, backend will exit the process. This result to a 503 error.
      // We here consider that such failures correspond to a maintenance state.
      if (
        // We here consider that such failure correspond to a maintenance state in DEV
        // CORS failure (OPTIONS query fails)
        (error.name === 'Error' && error.message === 'Failed to fetch') ||
        // We here consider that such failure correspond to a maintenance state in preprod/prod
        (error.name === 'ApiError' && error.message === 'common:common.error.api.default' && error.statusCode === 503)
      ) {
        dispatch(maintenanceActivate())
      }

      // Let's say I am a non-connected user, navigating while server is under maintenance.
      // /status request will return 503 error. That's alright. However, if maintenance mode is deactivated,
      // /status request will return 403, because this endpoint is protected from anonymous users.
      // We here remove maintenance state for non-connected users experiencing a 403.
      if (error.statusCode === 403 && !loadToken()) {
        dispatch(maintenanceDeactivate())
      }

      // If the error was thrown by fetch, use the standard message for network failures
      if (
        (error.name === 'FetchError' && error.code === 'ENOTFOUND') || // Thrown by SSR
        (error.name === 'TypeError' && error.message === 'Failed to fetch') // Thrown by SW
      ) {
        error = new ApiError('common:common.error.network-failure.short', 503)
      }

      dispatch(fetchApiFailure(key, error.message, meta))

      if (error.statusCode) {
        console.error(
          `Fetch failed for ${method} ${apiUrl + url} with params ${JSON.stringify(params)}.\n
             Response: ${error.message} (status code: ${error.statusCode})`,
        )
      } else {
        // If there is no status code, it probably means it's a runtime error
        console.error(error)
      }

      // Rethrow the error in case we want to use fetchApi().catch()
      // By default, errors are not rethrown to avoid possible uncaught errors
      if (throwError) throw error
      return undefined
    }
  }
