import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { selectMaintenanceStatus } from '@hozana/api/selectors'
import { useIsHydrated } from '@hozana/hooks/useIsHydrated'
import { Translate } from '@hozana/intl/Translate'
import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'
import { useScreen } from '@hozana/screen/useScreen'
import type { TResponsiveVisibilityProps } from '@hozana/styling/responsive'
import { QA } from '@hozana/tests/constants'
import { GTM_AUTH_SOURCE, TRACKING_SOURCE, UTM } from '@hozana/tracking/constants'

import type { TButtonColorName } from 'elements/button/BaseButton'
import { Button } from 'elements/button/Button'
import { RippledButton, TRippledButtonProps } from 'elements/button/RippleButton'
import { Cell } from 'elements/layout/Cell'
import { Div, TDivProps } from 'elements/layout/Div'
import { Row } from 'elements/layout/Row'
import { Icon, TIconProps } from 'elements/media/Icon'
import { Ellipsis } from 'elements/text/Ellipsis'
import { EllipsisMultiLines } from 'elements/text/EllipsisMultiLines'
import { Link, TLinkProps } from 'elements/text/Link'
import { Badge, TBadgeProps } from 'elements/ui/Badge'
import { Logo } from 'elements/ui/Logo'

import { ICON } from 'config/icons'
import { openPopin } from 'general/actions'
import { DONATION_SOURCE, FULL_STATIC_PAGES, QUERY_ACTION, QUERY_AUTH_SOURCE } from 'general/constants'
import { POPINS } from 'general/managers/popins/constants'
import { PAGE } from 'routes/constants'

import { CONNECT_TAB, CONNECT_TAB_NAME } from 'modules/auth/constants'
import { JoinButton } from 'modules/community/components/JoinButton'
import type { TJoinButtonProps } from 'modules/community/types'
import { InviteButton } from 'modules/share/components/InviteButton'
import { ShareContacts } from 'modules/share/components/ShareContacts'
import { ShareProvider, TShareProviderProps } from 'modules/share/components/ShareProvider'
import { ShareSocial } from 'modules/share/components/ShareSocial'
import { SHARE_OBJECTS, SHARE_TYPES } from 'modules/share/constants'
import { TUserProfileLinkProps } from 'modules/user/components/UserProfileLink'

import { LanguageItem } from './LanguageItem'

export const HeaderItemWrapper = styled(Div).attrs(() => ({
  /* The header-item-wrapper class is used by full-ssr-functions #headerSizeToggling */
  className: 'header-item-wrapper',
}))`
  height: ${({ theme }) => theme.sizes.headerItem};

  /* Header size toggling on HomePage #headerSizeToggling */
  transition: height 0.3s;
  &.big-header-item-wrapper {
    height: 5em !important;
  }
`

export type TTitlePageProps = {
  bigTitle?: boolean
}

/** Screen is defined to "xs: true" by default in SSR, even on desktop,
 * so we force that component to be visible only on mobile, by CSS
 */
export const TitlePage: React.FC<TTitlePageProps> = ({ children, bigTitle }) => (
  <HeaderItemWrapper as={Row} visible="xs">
    <Cell flex="1" m="auto 0px" p="0 5px 0 5px" maxH="38px" noOverflow>
      <EllipsisMultiLines
        fontSize={bigTitle ? '1em' : '1em'}
        lineHeight="1em"
        color="black70"
        lines={bigTitle ? '1' : '2'}
      >
        {children}
      </EllipsisMultiLines>
    </Cell>
  </HeaderItemWrapper>
)

export const LanguageItemBlock: React.FC = () => (
  <HeaderItemWrapper align="middleCenter" w="auto">
    <LanguageItem />
  </HeaderItemWrapper>
)

export type TLogoBlockProps = {
  isLogged?: boolean
}

export const LogoBlock: React.FC<TLogoBlockProps> = ({ isLogged }) => {
  const { t } = useTranslation()
  const screen = useScreen()
  const { pathname } = useRouter()

  const isSsrOnlyPage = FULL_STATIC_PAGES.includes(pathname)
  const fullLogo = screen.lg || screen.xl || (pathname === PAGE.INTENTIONS && !isLogged) || isSsrOnlyPage

  return (
    <Link m="auto" data-testid={QA.COMMON.HEADER.LOGO_BUTTON} to={{ pathname: isLogged ? PAGE.USER_FEED : PAGE.HOME }}>
      <HeaderItemWrapper
        as={Div}
        align="middleCenter"
        id={fullLogo ? 'header-logo-full' : 'header-logo-small'}
        w="auto"
        m="0 10px"
      >
        {__CLIENT__ || isSsrOnlyPage ? (
          <Logo full={fullLogo} color="black30" fill="black30" label={t('common:app.back-to-home')} />
        ) : null}
      </HeaderItemWrapper>
    </Link>
  )
}

export const DonationLink: React.FC = () => (
  <HeaderItemWrapper align="middleCenter">
    <Button
      id="donation-button"
      icon={ICON.HEART}
      color="red"
      bg="lightRed"
      hoverBg="lightRed"
      borderColor="lightRed"
      hoverColor="darkRed"
      hoverBorderColor="lightRed"
      data-testid={QA.COMMON.HEADER.DONATE_BUTTON}
      valueLink={{
        to: {
          pathname: PAGE.INTENTIONS,
          query: { action: QUERY_ACTION.SHOW_DONATION_BANNER, source: DONATION_SOURCE.HEADER },
        },
        reload: true,
      }}
    >
      trans:common:app.i-donate
    </Button>
  </HeaderItemWrapper>
)

export type TLinkButtonProps = TResponsiveVisibilityProps & Pick<TLinkProps, 'to'>

export const HeaderLinkButton: React.FC<TLinkButtonProps> = ({ to, children, visible }) => (
  <HeaderItemWrapper align="middleCenter" w="auto" m="0 5px 0 0" visible={visible}>
    <Button valueLink={to && { to }} reverseColors>
      {children}
    </Button>
  </HeaderItemWrapper>
)

export type TConnectButtonProps = {
  'data-testid'?: string
  tab: CONNECT_TAB
  reverseColors?: boolean
  colors?: TButtonColorName
} & TDivProps

export const ConnectButton: React.FC<TConnectButtonProps> = ({
  children,
  tab,
  'data-testid': dataTestId,
  reverseColors,
  colors,
  ...otherProps
}) => {
  const { pathname } = useRouter()
  const isHydrated = useIsHydrated()
  const dispatch = useDispatch()

  const isLogin = tab === CONNECT_TAB.LOGIN

  const onClick = () => {
    dispatch(
      openPopin(POPINS.ConnectPopin, {
        authSource: isLogin ? GTM_AUTH_SOURCE.HEADER_LOGIN : GTM_AUTH_SOURCE.HEADER_SIGNUP,
        connectDetails: {
          displayWizardOnSignup: true,
          ...(pathname === PAGE.HOME && { pathname: PAGE.USER_FEED }),
        },
      }),
    )
  }

  return (
    <HeaderItemWrapper align="middleCenter" w="auto" m="0 5px 0 0" {...otherProps}>
      <Button
        onClick={onClick}
        reverseColors={reverseColors}
        colors={colors}
        data-testid={dataTestId}
        valueLink={
          !isHydrated && {
            to: {
              pathname: PAGE.CONNECT,
              query: {
                ...(isLogin && { [CONNECT_TAB_NAME]: CONNECT_TAB.LOGIN }),
                authSource: isLogin ? QUERY_AUTH_SOURCE.HEADER_LOGIN : QUERY_AUTH_SOURCE.HEADER_SIGNUP,
              },
            },
          }
        }
      >
        {children}
      </Button>
    </HeaderItemWrapper>
  )
}

export const LoginButton: React.FC<Omit<TConnectButtonProps, 'tab'>> = (props) => (
  <ConnectButton tab={CONNECT_TAB.LOGIN} data-testid={QA.COMMON.HEADER.LOGIN_BUTTON} {...props}>
    trans:common:auth.login.login
  </ConnectButton>
)

export const SignupButton: React.FC<Omit<TConnectButtonProps, 'tab'>> = (props) => (
  <ConnectButton tab={CONNECT_TAB.SIGNUP} data-testid={QA.COMMON.HEADER.SIGNUP_BUTTON} invisible="xs" {...props}>
    trans:common:auth.signup.signup
  </ConnectButton>
)

export type TShareSocialBlockProps = Pick<TShareProviderProps, 'community' | 'publication'>

export const ShareSocialBlock: React.FC<TShareSocialBlockProps> = ({ community, publication }) => {
  const screen = useScreen()

  return publication && community ? (
    <ShareProvider
      objectToShare={SHARE_OBJECTS.PUBLICATION}
      trackingSource={TRACKING_SOURCE.PUBLICATION_HEADER}
      community={community}
      publication={publication}
    >
      <HeaderItemWrapper align="middleCenter" w="auto">
        {screen.xs ? (
          <ShareSocial shareTypes={[SHARE_TYPES.MESSENGER, SHARE_TYPES.WHATSAPP]} />
        ) : (
          <>
            <ShareSocial
              shareTypes={
                screen.sm
                  ? [SHARE_TYPES.FACEBOOK, SHARE_TYPES.PINTEREST]
                  : [SHARE_TYPES.WHATSAPP, SHARE_TYPES.FACEBOOK, SHARE_TYPES.TWITTER, SHARE_TYPES.PINTEREST]
              }
            />
            {!screen.sm && <ShareContacts />}
          </>
        )}
      </HeaderItemWrapper>
    </ShareProvider>
  ) : null
}

export type TCommunityJoinButtonsProps = Pick<TJoinButtonProps, 'community'>

export const CommunityJoinButtons: React.FC<TCommunityJoinButtonsProps> = ({ community }) => {
  const screen = useScreen()
  const isMaintenanceStateActive = useSelector((state) => selectMaintenanceStatus(state))

  return (
    !isMaintenanceStateActive && (
      <HeaderItemWrapper align="middleCenter" w="auto" m="0 5px 0 0">
        <JoinButton
          community={community}
          trackingSource={TRACKING_SOURCE.HEADER}
          // No event origin here because it is already on the community's page
        >
          {!(screen.xs || screen.sm) ? (
            <Translate values={{ lifetime: community.lifetime }}>trans:common:community.join.long</Translate>
          ) : undefined}
        </JoinButton>
      </HeaderItemWrapper>
    )
  )
}

export const InviteBlock: React.FC<TCommunityJoinButtonsProps> = ({ community }) => {
  const isMaintenanceStateActive = useSelector((state) => selectMaintenanceStatus(state))
  const screen = useScreen()
  return (
    !isMaintenanceStateActive && (
      <HeaderItemWrapper align="middleCenter" w="auto" m="0 5px 0 0">
        <Div>
          <InviteButton
            objectToShare={SHARE_OBJECTS.COMMUNITY}
            trackingSource={TRACKING_SOURCE.COMMUNITY_HEADER}
            community={community}
            utmContent={UTM.CONTENT.COMMUNITY_PAGE_HEADER_INVITE_BUTTON}
          >
            {screen.xs ? 'trans:common:share.invite' : undefined}
          </InviteButton>
        </Div>
      </HeaderItemWrapper>
    )
  )
}

// TABS

type TSelectedProp = {
  selected?: boolean
}

const LegendWrapper = styled(Div)<TSelectedProp>`
  height: '12px';
  line-height: 0;
`

const StyledIcon = styled(Icon)<TSelectedProp>`
  transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
`

export type THeaderTabProps = {
  icon: TIconProps['name']
  number?: TBadgeProps['number']
  isDot?: TBadgeProps['isDot']
} & TSelectedProp &
  TRippledButtonProps

export const HeaderTab: React.FC<THeaderTabProps> = ({ selected, children, icon, number, isDot, ...otherProps }) => {
  const screen = useScreen()

  return (
    <Div align="center">
      <RippledButton color="black50" displayBlock {...otherProps}>
        <HeaderItemWrapper w={{ xs: 'auto', sm: '93px' }} h="60px" align="middleCenter" column>
          <Badge isDot={isDot} number={number} borderColor={screen.xs ? 'white' : 'background'}>
            <StyledIcon name={icon} color={selected ? 'yellow' : 'black50'} size="24px" />
          </Badge>
          <LegendWrapper maxW="100%" align="center" m="4px 0 0">
            <Ellipsis color={selected ? 'yellow' : 'black50'} fontSize="13px" lineHeight="1em">
              {children}
            </Ellipsis>
          </LegendWrapper>
        </HeaderItemWrapper>
      </RippledButton>
    </Div>
  )
}

export const FeedPageTab: React.FC<Partial<THeaderTabProps>> = (props) => (
  <HeaderTab valueLink={{ to: { pathname: PAGE.USER_FEED }, reload: true }} icon={ICON.HOZANA_PRAYER_SPACE} {...props}>
    trans:common:app.navigate.tab.feed
  </HeaderTab>
)

export const CommunitiesTab: React.FC<Partial<THeaderTabProps>> = ({ onClick, ...props }) => {
  const router = useRouter()
  return (
    <HeaderTab
      onClick={(e) => {
        router.push({ pathname: PAGE.COMMUNITY_LIST, hostname: window.location.hostname })
        if (onClick) onClick(e)
      }}
      icon={ICON.HOZANA_SEARCH}
      {...props}
    >
      trans:common:app.navigate.tab.communities
    </HeaderTab>
  )
}

export type TMessageViewHeaderProps = {
  correspondentId: TUserProfileLinkProps['userId']
}
