import React from 'react'
import styled from 'styled-components'

import { QA } from '@hozana/tests/constants'

import { ClearableInput, TClearableInputProps } from 'elements/input/ClearableInput'
import { AbsoluteDiv } from 'elements/layout/AbsoluteDiv'
import { Div, TDivProps } from 'elements/layout/Div'
import { Icon } from 'elements/media/Icon'

import { ICON } from 'config/icons'

export const StyledClearableInput = styled(ClearableInput)`
  color: ${(props) => props.color};
  border-radius: 30px;
  padding: 10px 3em;
  transition: all 0.3s ease-in-out;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  // Overwrite sanitize ::selection
  && {
    border: 1px solid ${(props) => props.theme.colors[props.borderColor]};
  }
`

const StyledDiv = styled(Div)`
  &:focus-within {
    opacity: 1 !important;
  }
`

const StyledAbsoluteDiv = styled(AbsoluteDiv)`
  pointer-events: none;
`

export type TSearchInputProps = TClearableInputProps & Pick<TDivProps, 'opacity'>

export const SearchInput = React.forwardRef<HTMLInputElement, TSearchInputProps>(
  ({ fontSize, opacity, ...otherProps }, ref) => (
    <StyledDiv {...{ fontSize, opacity }}>
      <StyledAbsoluteDiv
        top
        bottom
        left
        align="middleCenter"
        m="0 1em"
        zIndex="zIndex2"
        data-testid={QA.COMMON.ROUNDED_SEARCH_INPUT}
      >
        <Icon name={ICON.MAGNIFIER} size="1.5em" color={otherProps.color} />
      </StyledAbsoluteDiv>
      <StyledClearableInput ref={ref} role="search" {...otherProps} />
    </StyledDiv>
  ),
)

SearchInput.displayName = 'SearchInput'
