import { isIOSWebview } from '@hozana/screen/functions/isIOSWebview'
import { cookie } from '@hozana/storage/cookies'

import { COOKIES_CONFIG } from 'general/managers/cookies/constants'
import { COOKIES } from 'general/managers/cookies/cookieNames'

const debutModeOffMessage = `Debug mode off.

A page reload is needed to take changes into account.
Reload now?`

const getDebugModeOnMessage = (hours: number) => `DebugMode on.

It will remain on for ${hours}h, even if you close the tab, but you can turn it off with 'off'.
You can also set the hours parameters to customize the time you want it to remain on.

A page reload is needed to take changes into account.
Reload now?`

const getAppModeOnMessage = (hours: number) => `AppMode on.
Warning! It will remain on for ${hours}h, even if you close the tab, but you can turn it off with 'off'.
You can also set the hours parameters to customize the time you want it to remain on.`

type TOnOff = 'on' | 'off'

export const getAppMode = () => isIOSWebview()

export type TSetAppModeInput = TOnOff | 'true' | 'false' | boolean

export const setAppMode = (newAppMode: TSetAppModeInput, hours = 24) => {
  switch (newAppMode) {
    case true:
    case 'true':
    case 'on':
      cookie.save(COOKIES.isNative, true, {
        ...COOKIES_CONFIG.SESSION,
        expires: new Date(Date.now() + 1000 * 60 * 60 * hours),
      })
      alert(getAppModeOnMessage(hours))
      break

    case false:
    case 'false':
    case 'off':
      cookie.remove(COOKIES.isNative, COOKIES_CONFIG.SESSION)
      console.log('AppMode off.')
      break

    default:
      console.error(`setAppMode argument should be either 'on', 'off', or a boolean.`)
      break
  }
}

const specificModes = ['gtm', 'form'] as const
type TSpecificMode = (typeof specificModes)[number]

export function getDebugMode(): Partial<Record<TSpecificMode, boolean>> | undefined
export function getDebugMode(mode?: TSpecificMode): boolean
export function getDebugMode(mode?: TSpecificMode) {
  const debugMode = cookie.load<Partial<Record<TSpecificMode, boolean>>>(COOKIES.debugMode)
  return mode ? debugMode?.[mode] : debugMode
}

export type TSetDebugModeInput =
  | TOnOff
  | TSpecificMode
  | `${TSpecificMode},${TSpecificMode}`
  | `${TSpecificMode},${TSpecificMode},${TSpecificMode}`
  | boolean

export const setDebugMode = (newDebugMode: TSetDebugModeInput, hours = 24) => {
  let reload = false

  const cookieConfig = {
    ...COOKIES_CONFIG.SESSION,
    expires: new Date(Date.now() + 1000 * 60 * 60 * hours),
  }

  if (['false', 'off', false].includes(newDebugMode)) {
    cookie.remove(COOKIES.debugMode, cookieConfig)
    reload = confirm(debutModeOffMessage)
  } else {
    const modes =
      typeof newDebugMode === 'string' &&
      newDebugMode.split(',').reduce(
        (acc, modeItem: TSpecificMode) =>
          specificModes.includes(modeItem)
            ? {
                ...acc,
                [modeItem]: true,
              }
            : acc,
        {},
      )

    if (['true', 'on', true].includes(newDebugMode) || (modes && Object.keys(modes).length > 0)) {
      cookie.save(COOKIES.debugMode, modes || {}, cookieConfig)
      reload = confirm(getDebugModeOnMessage(hours))
    } else {
      console.error(
        `setDebugMode argument should be either 'on', 'off', a boolean, or a comma separated list of specific modes: 'gtm', 'form'.`,
      )
    }
  }

  if (reload) {
    window?.location.reload()
  }
}
