import React, { MouseEventHandler, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { useUpdateEffect } from 'usehooks-ts'

import { useEventListener } from '@hozana/hooks/useEventListener'
import { useSyncedRef } from '@hozana/hooks/useSyncedRef'
import { useScreen } from '@hozana/screen/useScreen'
import { KEYS } from '@hozana/utils/constants'

import { AbsoluteDiv, TAbsoluteDivProps } from 'elements/layout/AbsoluteDiv'
import { Div } from 'elements/layout/Div'
import { Overlay } from 'elements/layout/Overlay'
import { Ul } from 'elements/layout/Ul'

import { POPOVER_CLASS } from 'general/constants'
import { MobilePopover, TMobilePopoverProps } from 'general/structure/Popover/MobilePopover'

import { PopoverButton } from './PopoverButton'

const slideUp = keyframes`
  from  { transform: translateY(20px); opacity: 0.2; }
  to    { transform: translateY(0px); opacity: 1; }
`

const slideDown = keyframes`
  from  { transform: translateY(-20px); opacity: 0.2; }
  to    { transform: translateY(0px); opacity: 1; }
`

export const Wrapper: React.FC = ({ children }) => (
  <Div w="100%" noPrint className={POPOVER_CLASS}>
    {children}
  </Div>
)

type TFloatingWrapperProps = {
  /** On tablet or desktop, the menu will appear on fixed position */
  fixed?: boolean
  /** On tablet or desktop, by default, the menu will appear with a slide down animation */
  slideUp?: boolean
  slideDown?: boolean
} & TAbsoluteDivProps

// Tablet or desktop
const FloatingWrapper = styled(AbsoluteDiv)<TFloatingWrapperProps>`
  ${(props) => props.fixed && 'position: fixed'};
  border-radius: 10px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  animation: ${(props) => (props.slideUp ? slideUp : slideDown)} 300ms ease forwards;
`

type TTriggerComponentProps = { clicked?: boolean; onClick: MouseEventHandler } & Record<string, any>

type TSimpleMobilePopoverProps = {
  handleOverlayClick: React.MouseEventHandler<HTMLElement>
  enhancedChildren: React.ReactNode
  anchor?: Element
} & TMobilePopoverProps

const SimpleMobilePopover: React.FC<TSimpleMobilePopoverProps> = ({
  handleOverlayClick,
  enhancedChildren,
  ornament,
  anchor,
}) => {
  const mobileElement = (
    <MobilePopover ornament={ornament} onClickOverlay={handleOverlayClick}>
      <Ul>{enhancedChildren}</Ul>
    </MobilePopover>
  )
  return anchor ? createPortal(mobileElement, anchor) : mobileElement
}

type TSimpleDesktopPopoverProps = {
  anchor?: Element
  handleOverlayClick: React.MouseEventHandler<HTMLElement>
  enhancedChildren: React.ReactNode
  isCloseButtonDisplayed: boolean
  setIsCloseButtonDisplayed: (isDisplayed: boolean) => void
  togglePopover: VoidFunction
  isOpen: boolean
} & TPopoverProps

const SimpleDesktopPopover: React.FC<TSimpleDesktopPopoverProps> = ({
  handleOverlayClick,
  enhancedChildren,
  anchor,
  fixed,
  isCloseButtonDisplayed,
  setIsCloseButtonDisplayed,
  togglePopover,
  isOpen,
  doScrollIntoView = false,
  ...otherProps
}) => {
  const floatingWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen && doScrollIntoView) {
      floatingWrapperRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isOpen, doScrollIntoView])

  const desktopElement = (
    <>
      <Overlay onClick={handleOverlayClick} />
      <FloatingWrapper
        ref={floatingWrapperRef}
        zIndex="popover"
        fixed={fixed}
        slideUp={slideUp}
        bg="white"
        align="left"
        {...otherProps}
        role="dialog"
        aria-modal="true"
      >
        <Ul>
          {enhancedChildren}

          {/* Special button for accessibility: be able to close popover by keyboard navigation */}
          <PopoverButton
            onFocus={() => setIsCloseButtonDisplayed(true)}
            onBlur={() => setIsCloseButtonDisplayed(false)}
            onClick={() => {
              setIsCloseButtonDisplayed(false)
              togglePopover()
            }}
            style={{
              height: isCloseButtonDisplayed ? 'auto' : '0px',
              overflow: 'hidden',
            }}
          >
            trans:common:app.popover.close-button
          </PopoverButton>
        </Ul>
      </FloatingWrapper>
    </>
  )
  return anchor ? createPortal(desktopElement, anchor) : desktopElement
}

export type TPopoverProps = {
  /** The trigger component (Chevron, FloatingButton, etc.) */
  triggerComponent?: React.ReactElement<TTriggerComponentProps>
  /** Wether the popover is rendered or not */
  open?: boolean
  /** Function executed on openning */
  onOpening?: () => void
  /** Function executed on closing */
  onClosing?: () => void
  // Where to attach the popover
  anchor?: Element
  // If we want to scroll into view on popover click
  doScrollIntoView?: boolean
} & TFloatingWrapperProps &
  TMobilePopoverProps

/**
 * ### Popover ###
 */
export const Popover: React.FC<TPopoverProps> = ({
  children,
  triggerComponent,
  onOpening,
  onClosing,
  fixed,
  open,
  onClickOverlay,
  ornament,
  anchor,
  doScrollIntoView = false,
  onClick,
  ...otherProps
}) => {
  const screen = useScreen()
  const [isOpen, setIsOpen] = useState(open ?? false)
  const [isCloseButtonDisplayed, setIsCloseButtonDisplayed] = useState(false)
  const onOpeningRef = useSyncedRef(onOpening)
  const onClosingRef = useSyncedRef(onClosing)

  /**
   * @param impOpen If undefined, will toggle the popover.
   * If boolean, will force open (true) or close (false) the popover.
   * If function, impOpen(prevOpen) will force open or close the popover.
   */
  const togglePopover = useCallback((impOpen?: boolean | ((prevIsOpen: boolean) => boolean)) => {
    setIsOpen((prevIsOpen) => (typeof impOpen === 'function' ? impOpen(prevIsOpen) : impOpen ?? !prevIsOpen))
  }, [])

  useUpdateEffect(() => {
    if (isOpen) {
      onOpeningRef.current?.()
    } else {
      onClosingRef.current?.()
    }
  }, [isOpen, onClosingRef, onOpeningRef])

  // Update popover state on open prop change
  useEffect(() => togglePopover((prevOpen) => open ?? prevOpen), [open, togglePopover])

  // Close popover on Esc
  useEventListener('keydown', (e: KeyboardEvent) => {
    if (KEYS.ESC.includes(e.key || e.keyCode)) {
      togglePopover(false)
    }
  })

  const enhancedChildren = React.Children.map(children, (child) =>
    // Checking isValidElement is the safe way and avoids a typescript error too.
    React.isValidElement(child)
      ? React.cloneElement(child as ReactElement<{ closePopover: VoidFunction } & TAnyProps>, {
          closePopover: () => togglePopover(false),
        })
      : child,
  ) as ReactNode

  const handleOverlayClick: React.MouseEventHandler<HTMLElement> = (e) => {
    togglePopover(false)
    onClickOverlay?.(e)
  }

  return (
    <>
      {React.isValidElement(triggerComponent)
        ? React.cloneElement(triggerComponent, {
            clicked: isOpen,
            onClick: (e?: React.MouseEvent<HTMLDivElement>) => {
              togglePopover()
              onClick?.(e)
            },
          })
        : triggerComponent}

      {/* MOBILE */}
      {isOpen &&
        (screen.xs || screen.sm ? (
          <SimpleMobilePopover {...{ ornament, anchor, enhancedChildren, handleOverlayClick }} />
        ) : (
          <SimpleDesktopPopover
            {...{
              fixed,
              isCloseButtonDisplayed,
              setIsCloseButtonDisplayed,
              handleOverlayClick,
              enhancedChildren,
              anchor,
              togglePopover,
              isOpen,
              doScrollIntoView,
            }}
            {...otherProps}
          />
        ))}
    </>
  )
}
