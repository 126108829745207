import { useTranslate } from '@hozana/intl/useTranslate'
import { useSelector } from '@hozana/redux/hooks'

import { selectTitle } from 'general/selectors'

export const useTitle = (fallback = 'common:common.title.default') => {
  const rawTitle = useSelector(selectTitle)

  const [key = fallback, values] = typeof rawTitle === 'string' ? [rawTitle] : rawTitle || []

  return useTranslate(key, values)
}
