import 'regenerator-runtime/runtime'

import { fetchApiSaga } from '@hozana/api/sagas'
import { formatSubmissionError } from '@hozana/form/functions'
import { all, put, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'
import { GTM_AUTH_SOURCE } from '@hozana/tracking/constants'
import { GTM } from '@hozana/tracking/gtm'

import { addFlash } from 'general/actions'
import { QUERY_ACTION } from 'general/constants'
import { PAGE } from 'routes/constants'

import { runConnect } from 'modules/auth/sagas'

import {
  TDeleteTestimonyMutationPayload,
  TEditTestimonyMutationPayload,
  TPostTestimonyMutationPayload,
  deleteTestimonyMutation,
  editTestimonyMutation,
  postTestimonyMutation,
} from './queries'
import { deleteTestimony, editTestimony, postTestimony } from './routines'

function* postTestimonySaga({ payload: { content, userId } }: TSagaProps<TPostTestimonyMutationPayload>) {
  try {
    const isAuthenticated = yield* runConnect({
      authSource: GTM_AUTH_SOURCE.POST_TESTIMONY,
      initialTitle: 'testimony:testimony.write.auth-required',
      connectDetails: {
        redirectUrl: undefined,
        /**
         * After social login on mobile, on reload Hozana, redirect to testimonies page with `?action=post&content=${content}`
         * so that the postIntentionSaga (which has been interrupted by social connect) is retriggered to be performed
         */
        reloadUrl: {
          pathname: PAGE.TESTIMONIES,
          query: {
            action: QUERY_ACTION.POST,
            content,
          },
        },
      },
    })
    if (!isAuthenticated) {
      yield* put(postTestimony.failure('cancel'))
    } else {
      yield* fetchApiSaga(postTestimonyMutation({ content, userId }))

      GTM.trackEvent(GTM.EVENTS.TESTIMONY_ADDED)

      yield* put(postTestimony.success())
      yield* put(addFlash({ message: 'testimony:testimony.is-published' }))
    }
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(postTestimony.failure(formatSubmissionError(error.message)))
  }
}

function* editTestimonySaga({ payload: { content, testimonyId, userId } }: TSagaProps<TEditTestimonyMutationPayload>) {
  try {
    yield* fetchApiSaga(editTestimonyMutation({ content, testimonyId, userId }))

    GTM.trackEvent(GTM.EVENTS.TESTIMONY_EDITED)

    yield* put(editTestimony.success())
    yield* put(addFlash({ message: 'testimony:testimony.is-edited' }))
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(editTestimony.failure(formatSubmissionError(error.message)))
  }
}

function* deleteTestimonySaga({ payload: { testimonyId, userId } }: TSagaProps<TDeleteTestimonyMutationPayload>) {
  try {
    yield* fetchApiSaga(deleteTestimonyMutation({ testimonyId, userId }))

    GTM.trackEvent(GTM.EVENTS.TESTIMONY_DELETED)

    yield* put(deleteTestimony.success())
    yield* put(addFlash({ message: 'testimony:testimony.is-deleted' }))
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(deleteTestimony.failure(formatSubmissionError(error.message)))
  }
}

/*
 * LISTENER
 */

export default function* testimonySagasListener() {
  yield* all([
    takeLatest(postTestimony, postTestimonySaga),
    takeLatest(editTestimony, editTestimonySaga),
    takeLatest(deleteTestimony, deleteTestimonySaga),
  ])
}
