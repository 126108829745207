import React from 'react'
import styled, { css } from 'styled-components'

import { FixedDiv, TFixedDivProps } from './FixedDiv'

export type TOverlayProps = TFixedDivProps & {
  noPointerEvents?: boolean
}

export const StyledFixedDiv = styled(FixedDiv)<TOverlayProps>`
  ${({ noPointerEvents }) =>
    noPointerEvents &&
    css`
      pointer-events: none;
    `}
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`

export const Overlay: React.FC<TOverlayProps> = ({
  left = true,
  right = true,
  top = true,
  bottom = true,
  bg = 'fadedBlack20',
  zIndex = 'overlay',
  ...props
}) => <FixedDiv {...{ left, right, top, bottom, bg, zIndex }} {...props} />
