import { toTitleCase } from '@hozana/utils/functions/strings'

import { Locale } from 'config/types'

import type { TConnectStep } from 'modules/auth/components/ConnectForm/constants'
import type { CONNECT_METHOD } from 'modules/auth/constants'

export const GTM_DATALAYER_NAME = 'gtmDL'

/* UTMS */
/* /!\ Changes here must be changed in /src/sw/index too /!\ */
export const UTM = {
  SOURCE: {
    SHARE: 'share',
    SITE_HOZANA: 'site_Hozana',
    INTERNAL: 'interne',
  },
  MEDIUM: {
    // Share buttons
    COPYPASTE: 'copypaste',
    COPYPASTE_EMAIL: 'copypaste_email',
    FB: 'FB',
    MAILTO: 'mailto',
    MESSENGER: 'Messenger',
    PINTEREST: 'Pinterest',
    TW: 'TW',
    WHATS_APP: 'Whats_app',
    OTHER_SHARE: 'other-share',
    // Links
    APP_LANDING_PAGE: 'app_landing_page',
    APP_MENU_LINK: 'app_menu_link',
    HOMEMADE_APP_PROMPT: 'homemade app install prompt',
    HOME_PAGE: 'homepage',
    INSTAGRAM: 'Instagram',
    YOUTUBE: 'Youtube',
    TIKTOK: 'Tiktok',
    PUBLICATION_HEADER: 'publication_header',
    STANDARD_WIDGET: 'widget_standard',
    // Guides
    GUIDE_COMMUNITY_CARD: 'guide_community_card',
    GUIDE_COMMUNITY_POPIN: 'guide_community_popin',
    GUIDE_INTENTION_BUTTON: 'guide_intention_button',
    GUIDE_INLINE_LINK: 'guide_inline_link',
  },
  CONTENT: {
    // Share buttons
    COMMUNITY_PAGE_URL_INVITE_POPIN: 'communityPageUrlInvitePopin',
    COMMUNITY_JOINED_POPIN_SHARE_BUTTON: 'communityJoinedPopinShareButton',
    HOZANA_URL_INVITE_POPIN: 'hozanaUrlInvitePopin',
    INTENTION_INVITE_BUTTON: 'intentionInviteButton',
    INTENTION_PAGE_SHARE_BUTTON: 'intentionPageShareButton',
    INVITE_TO_PRAY_INTENTIONS_MESSAGE: 'inviteToPrayIntentionsMessage',
    AFTER_PRAY_COMMUNITY_INVITE_BUTTON: 'afterPrayCommunityInviteButton',
    AFTER_PRAY_PUBLICATION_INVITE_BUTTON: 'afterPrayPublicationInviteButton',
    COMMUNITY_PRAY_SUCCESS_POPIN: 'communityPraySuccessPopin',
    PUBLICATION_BLOC_FOOTER_SHARE_BUTTON: 'publicationBlocFooterShareButton',
    PUBLICATION_PAGE_URL_INVITE_POPIN: 'publicationPageUrlInvitePopin',
    PUBLICATION_PAGE_SHARE_BUTTON: 'publicationPageShareButton',
    UNDEFINED_CONTENT_HOZANA: 'undefinedContent_hozana',
    UNDEFINED_CONTENT_PUBLICATION: 'undefinedContent_publication',
    UNDEFINED_CONTENT_COMMUNITY: 'undefinedContent_community',
    UNDEFINED_CONTENT_TESTIMONY: 'undefinedContent_testimony',
    UNDEFINED_CONTENT_PRAYER_MEETING: 'undefinedContent_prayerMeeting',
    UNDEFINED_CONTENT_INTENTION: 'undefinedContent_intention',
    COMMUNITY_PAGE_HEADER_INVITE_BUTTON: 'communityPageHeaderInviteButton',
    COMMUNITY_BLOCK_INVITE_BUTTON: 'communityBlockInvitePopin',
    COMMUNITY_PAGE_INVITE_BUTTON: 'communityPageInviteButton',
    COMMUNITY_PAGE_SHARE_BUTTON: 'communityPageShareButton',
    TESTIMONY_PAGE_SHARE_BUTTON: 'testimonyPageShareButton',
    PRAYER_MEETING_SHARE_BUTTON: 'prayerMeetingShareButton',
    // Links
    NEWS: 'news',
    SHARE: 'share',
    // from mail
    PUBLICATION_MAIL_MAILTO_SHARE_BUTTON: 'publicationMailMailtoShareButton',
    // Guides AB test versions
    VERSION_A: 'version_A',
    VERSION_B: 'version_B',
  },
  CAMPAIGN: {
    // Links to stores
    APP_STORE_HOZANA: 'app_store_Hozana',
    PLAY_STORE_HOZANA: 'play_store_Hozana',
    // Community or publication share buttons
    COMMUNITY: (id: number, slug: string): string => `${id}-${slug}`,
    INTENTION: (id: number): string => `Intention_${id}`,
    // Guides
    GUIDE_CONVERSION: 'guide_conversion',
  },
} as const

/* TRACKING SOURCES */

export const TRACKING_SOURCE = {
  AUTOMATIC: 'Automatic',
  CARET: 'Caret',
  COMMENT: 'Comment',
  COMMUNITIES_PAGE: 'Communities Page',
  COMMUNITY_CONFIRM_JOIN: 'Community Confirm Join',
  COMMUNITY_HEADER: 'Community Header',
  COMMUNITY_NEWSFEED: 'Community Newsfeed',
  COMMUNITY_PAGE_SEE_ALSO: 'See Also On Community Page',
  COMMUNITY_SIDEBAR: 'Community Sidebar',
  COMMUNITY_TOP_SECTION: 'Community Top Section',
  COMMUNITY_WIDGET: 'Community Widget',
  CONVERSATION: 'Conversation',
  EMAIL: 'Email',
  EMAIL_LINK: 'Email Link',
  FEED_PAGE: 'Feed Page',
  GUIDE_TEXT_LINK: 'Guide Text Link',
  GUIDE_CARD: 'Guide Card',
  GUIDE_POPIN: 'Guide Popin',
  GUIDE_POPIN_2: 'Guide Popin 2',
  HEADER: 'Header',
  HOME_PAGE: 'Home Page',
  HP_NEWSFEED: 'Hp Newsfeed',
  HP_LOGGED: 'Hp Logged',
  HP_UNLOGGED: 'Hp Unlogged',
  INTENTION: 'Intention',
  INTENTION_PAGE: 'Intention-Page',
  INTENTION_POSTPUBCTA: 'Intention-PostPubCTA',
  INTENTIONS_PAGE_INTENTION: 'Intentions-Page-Intention',
  INTENTIONS_PAGE_BIRTHDAY: 'Intentions-Page-Birthday',
  INTENTIONS_PANEL_INTENTION: 'Intentions-Panel-Intention',
  INTENTIONS_PANEL_BIRTHDAY: 'Intentions-Panel-Birthday',
  INTENTIONS_THEMATIC_PAGE: 'Intentions-Thematic-Page',
  INTENTIONS_WIDGET: 'Intentions-Widget',
  MANAGE_COMMUNITY_LABEL: (platform: 'mobile' | 'desktop', from: 'profile' | 'feed' | 'community'): string =>
    `${toTitleCase(platform)}-${toTitleCase(from)}`,
  POSTPRAY_PUBLICATION_BLOCK: 'Postpray Publication Block',
  POSTPRAY_SUCCESS_POPIN: 'Postpray Success Popin',
  POSTPRAY_COMMUNITY_PRAYER_BLOCK: 'Postpray Community Prayer Block',
  POSTPRAY_PUBLICATION_PAGE: 'Postpray Publication Page',
  PROFILE_PAGE: 'Profile Page',
  PROMPT_PUBLICATION_PAGE: 'Prompt On Publication Page',
  PROMPT_COMMUNITY_PAGE: 'Prompt On Community Page',
  PUBLICATION: 'Publication',
  PUBLICATION_HEADER: 'Publication Header',
  PUBLICATION_PAGE: 'Publication Page',
  PUBLICATION_SIDEBAR: 'Publication Sidebar',
  PUBLICATION_FOOTER_BANNER: 'Publication Footer Banner',
  SHARE: 'Share',
  SIDEBAR: 'Sidebar',
  SUGGESTIONS: 'Suggestions',
  SUGGESTIONS_NUM: (num: number): string => `Suggestions #${num}`,
  TESTIMONIALS_PAGE: 'Testimonials page',
  PRAYER_MEETING: 'Prayer meeting',
} as const

/* AUTHENTICATION SOURCES */

/** Autentication source tracking through Analytics (Cf. general/constants - AUTH_SOURCE for authentication source tracking in database) */
export enum GTM_AUTH_SOURCE {
  HEADER_SIGNUP = 'Button Signup Header',
  HEADER_LOGIN = 'Button Login Header',
  HOME_PAGE = 'Home Page Sign Up Button',
  NAVIGATION_SIGNUP = 'Button Signup Menu',
  NAVIGATION_LOGIN = 'Button Login Menu',
  JOIN_COMMUNITY = 'Join Community',
  LEAVE_COMMUNITY = 'Leave Community',
  SHOW_COMMUNITY_NOTIFS = 'Show Community Notifications',
  ADD_COMMENT = 'Add Comment',
  EDIT_COMMENT = 'Edit Comment',
  POST_TESTIMONY = 'Post Testimony',
  ADD_FAVORITE_PUBLICATION = 'Add Favorite Publication',
  SEND_MESSAGE_BUTTON = 'Send Message Button',
  ALREADY_TRACKED = 'Already Tracked', // Must not be sent to GTM
}

/* EVENTS */

export enum EVENTS {
  PAGEVIEW = 'PAGEVIEW',

  LEAD = 'LEAD',

  CONNECT_FORM_DISPLAYED = 'CONNECT_FORM_DISPLAYED',
  CONNECT_FORM_EMAIL_SUBMITTED = 'CONNECT_FORM_EMAIL_SUBMITTED',
  SIGNUP_FORM_PASSWORD_SUBMITTED = 'SIGNUP_FORM_PASSWORD_SUBMITTED',
  SIGNUP_FORM_NAME_FILLED = 'SIGNUP_FORM_NAME_FILLED',
  SIGNUP_FORM_CAPTCHA_VALID = 'SIGNUP_FORM_CAPTCHA_VALID',
  SIGNUP_FORM_SUBMITTED = 'SIGNUP_FORM_SUBMITTED',
  SIGNUP_FORM_SUBMIT_SUCCESS = 'SIGNUP_FORM_SUBMIT_SUCCESS',
  SIGNUP_FORM_SUBMIT_FAILURE = 'SIGNUP_FORM_SUBMIT_FAILURE',
  LOGIN_METHOD_FOUND = 'LOGIN_METHOD_FOUND',
  LOGIN_FORM_SUBMITTED = 'LOGIN_FORM_SUBMITTED',
  LOGIN_FORM_SUBMIT_SUCCESS = 'LOGIN_FORM_SUBMIT_SUCCESS',
  LOGIN_FORM_SUBMIT_FAILURE = 'LOGIN_FORM_SUBMIT_FAILURE',
  LOGOUT = 'LOGOUT',

  LOST_PASSWORD_OPENED = 'LOST_PASSWORD_OPENED',
  LOST_PASSWORD_FINISHED = 'LOST_PASSWORD_FINISHED',

  RESET_PASSWORD_OPENED = 'RESET_PASSWORD_OPENED',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',

  DON_CLICKED = 'DON_CLICKED',

  INVITE_DISPLAYED = 'INVITE_DISPLAYED',
  INVITE_FACEBOOK = 'INVITE_FACEBOOK', // INVITE_
  INVITE_TWITTER = 'INVITE_TWITTER', // INVITE_
  INVITE_WHATSAPP = 'INVITE_WHATSAPP', // INVITE_
  INVITE_MESSENGER = 'INVITE_MESSENGER', // INVITE_
  INVITE_PINTEREST = 'INVITE_PINTEREST', // INVITE_
  INVITE_MAILTO_OPEN_POPIN = 'INVITE_MAILTO_OPEN_POPIN', // INVITE_
  INVITE_MAILTO = 'INVITE_MAILTO', // INVITE_
  INVITE_COPY = 'INVITE_COPY', // INVITE_

  PARTAGE_DISPLAYED_COMMUNAUTE = 'PARTAGE_DISPLAYED_COMMUNAUTE',
  PARTAGE_DISPLAYED_HOME = 'PARTAGE_DISPLAYED_HOME',
  PARTAGE_FACEBOOK = 'PARTAGE_FACEBOOK', // PARTAGE_
  PARTAGE_TWITTER = 'PARTAGE_TWITTER', // PARTAGE_
  PARTAGE_WHATSAPP = 'PARTAGE_WHATSAPP', // PARTAGE_
  PARTAGE_MESSENGER = 'PARTAGE_MESSENGER', // PARTAGE_
  PARTAGE_PINTEREST = 'PARTAGE_PINTEREST', // PARTAGE_
  PARTAGE_MAILTO_OPEN_POPIN = 'PARTAGE_MAILTO_OPEN_POPIN', // PARTAGE_
  PARTAGE_MAILTO = 'PARTAGE_MAILTO', // PARTAGE_
  PARTAGE_COPY = 'PARTAGE_COPY', // PARTAGE_
  PARTAGE_EMBED = 'PARTAGE_EMBED',
  PARTAGE_EMBED_COPIER = 'PARTAGE_EMBED_COPIER',

  COMMUNAUTE_DECOUVRIR = 'COMMUNAUTE_DECOUVRIR',
  COMMUNAUTE_CLICKED = 'COMMUNAUTE_CLICKED',
  COMMUNAUTE_CREATION = 'COMMUNAUTE_CREATION',
  COMMUNAUTE_QUITTER_CLICKED = 'COMMUNAUTE_QUITTER_CLICKED',
  COMMUNAUTE_QUITTER_DONE = 'COMMUNAUTE_QUITTER_DONE',

  PRIERE_START = 'PRIERE_START',
  PRIERE_INTERRUPTED = 'PRIERE_INTERRUPTED',
  PRIERE_FINISHED = 'PRIERE_FINISHED',

  THANKS_PRAYER_CLICKED = 'THANKS_PRAYER_CLICKED',
  THANKS_PRAYER_DISMISSED = 'THANKS_PRAYER_DISMISSED',
  THANKS_PRAYER_DISPLAYED = 'THANKS_PRAYER_DISPLAYED',

  TESTIMONY_ADDED = 'TESTIMONY_ADDED',
  TESTIMONY_EDITED = 'TESTIMONY_EDITED',
  TESTIMONY_DELETED = 'TESTIMONY_DELETED',
  TESTIMONY_ADD_BUTTON_CLICKED = 'TESTIMONY_ADD_BUTTON_CLICKED',
  TESTIMONY_FIREWORKS_CLICKED = 'TESTIMONY_FIREWORKS_CLICKED',
  TESTIMONY_SEEMORE_CLICKED = 'TESTIMONY_SEEMORE_CLICKED',
  TESTIMONY_SHARE_BUTTON_CLICKED = 'TESTIMONY_SHARE_BUTTON_CLICKED',

  // experimental
  MENU = 'MENU',
  CHEVRON = 'CHEVRON',

  // old analytics, to be verified
  PUBLIER_COMMENTAIRE_LOGGED = 'PUBLIER_COMMENTAIRE_LOGGED',
  PUBLIER_COMMENTAIRE_LOGOUT = 'PUBLIER_COMMENTAIRE_LOGOUT',

  PWA_UPDATE_FOUND = 'PWA_UPDATE_FOUND',
  PWA_UPDATED = 'PWA_UPDATED',
  PWA_RELOAD_AFTER_UPDATE = 'PWA_RELOAD_AFTER_UPDATE',
  PWA_HOMESCREEN_PROMPTED = 'PWA_HOMESCREEN_PROMPTED',
  PWA_HOMESCREEN_ADDED = 'PWA_HOMESCREEN_ADDED',
  PWA_HOMESCREEN_DISMISSED = 'PWA_HOMESCREEN_DISMISSED',

  INTENTIONS_SHUFFLE = 'INTENTIONS_SHUFFLE',
  INTENTION_ADDED = 'INTENTION_ADDED',
  INTENTION_DELETED = 'INTENTION_DELETED',

  PUSH_PERMISSION_TRIGGERED = 'PUSH_PERMISSION_TRIGGERED',
  PUSH_PERMISSION_GRANTED = 'PUSH_PERMISSION_GRANTED',
  PUSH_PERMISSION_DENIED = 'PUSH_PERMISSION_DENIED',
  PUSH_PERMISSION_IGNORED = 'PUSH_PERMISSION_IGNORED',
  PUSH_ASK_DISPLAYED = 'PUSH_ASK_DISPLAYED',
  PUSH_ASK_ACCEPTED = 'PUSH_ASK_ACCEPTED',
  PUSH_ASK_REFUSED = 'PUSH_ASK_REFUSED',
  ALERT_BANNER_DISPLAYED = 'ALERT_BANNER_DISPLAYED',
  ALERT_BANNER_CLOSED = 'ALERT_BANNER_CLOSED',
  ALERT_BANNER_CLICKED = 'ALERT_BANNER_CLICKED',
  INSTALL_APP_BANNER_DISPLAYED = 'INSTALL_APP_BANNER_DISPLAYED',
  INSTALL_APP_BANNER_CLICKED = 'INSTALL_APP_BANNER_CLICKED',
  INSTALL_APP_BANNER_DISMISSED = 'INSTALL_APP_BANNER_DISMISSED',
  PROMPT_RATE_APP_DISPLAYED = 'PROMPT_RATE_APP_DISPLAYED',
  PROMPT_RATE_APP_CLICKED = 'PROMPT_RATE_APP_CLICKED',
  PROMPT_RATE_APP_DISMISSED = 'PROMPT_RATE_APP_DISMISSED',
  RATE_APP_LIKE = 'RATE_APP_LIKE',
  RATE_APP_DISLIKE = 'RATE_APP_DISLIKE',
  RATE_APP_DISLIKE_WRITE_TO_TEAM = 'RATE_APP_DISLIKE_WRITE_TO_TEAM',
  PROMPT_MAIL_DELIVERY_ISSUE_DISPLAYED = 'PROMPT_MAIL_DELIVERY_ISSUE_DISPLAYED',
  PROMPT_MAIL_DELIVERY_ISSUE_CLICKED = 'PROMPT_MAIL_DELIVERY_ISSUE_CLICKED',
  PROMPT_MAIL_DELIVERY_ISSUE_DISMISSED = 'PROMPT_MAIL_DELIVERY_ISSUE_DISMISSED',

  COMMUNITY_PROMPT_JOIN_DISPLAYED = 'COMMUNITY_PROMPT_JOIN_DISPLAYED',
  PUBLICATION_PROMPT_JOIN_DISPLAYED = 'PUBLICATION_PROMPT_JOIN_DISPLAYED',
  COMMUNITY_PROMPT_JOIN_DISMISSED = 'COMMUNITY_PROMPT_JOIN_DISMISSED',
  PUBLICATION_PROMPT_JOIN_DISMISSED = 'PUBLICATION_PROMPT_JOIN_DISMISSED',
  COMMUNITY_PROMPT_JOIN_CLICK_COMMUNITY = 'COMMUNITY_PROMPT_JOIN_CLICK_COMMUNITY',
  PUBLICATION_PROMPT_JOIN_CLICK_COMMUNITY = 'PUBLICATION_PROMPT_JOIN_CLICK_COMMUNITY',
  COMMUNITY_PROMPT_JOIN_CLICK_JOIN = 'COMMUNITY_PROMPT_JOIN_CLICK_JOIN',
  PUBLICATION_PROMPT_JOIN_CLICK_JOIN = 'PUBLICATION_PROMPT_JOIN_CLICK_JOIN',

  MANAGE_COMMUNITY_DISPLAY_TOOLBAR = 'MANAGE_COMMUNITY_DISPLAY_TOOLBAR',
  MANAGE_COMMUNITY_SWITCH_COMMUNITY = 'MANAGE_COMMUNITY_SWITCH_COMMUNITY',
  MANAGE_COMMUNITY_SEE_CLICKED = 'MANAGE_COMMUNITY_SEE_CLICKED',
  MANAGE_COMMUNITY_CREATE_PUBLICATION = 'MANAGE_COMMUNITY_CREATE_PUBLICATION',
  MANAGE_COMMUNITY_EDIT_CLICKED = 'MANAGE_COMMUNITY_EDIT_CLICKED',
  MANAGE_COMMUNITY_MANAGE_ROLES = 'MANAGE_COMMUNITY_MANAGE_ROLES',
  MANAGE_COMMUNITY_SEND_ANNOUNCEMENTS = 'MANAGE_COMMUNITY_SEND_ANNOUNCEMENTS',
  MANAGE_COMMUNITY_VIEW_STATISTICS = 'MANAGE_COMMUNITY_VIEW_STATISTICS',
  MANAGE_COMMUNITY_DELETE_CLICKED = 'MANAGE_COMMUNITY_DELETE_CLICKED',

  WIZARD_THEMES_DISPLAYED = 'WIZARD_THEMES_DISPLAYED',
  WIZARD_THEMES_CLICKED = 'WIZARD_THEMES_CLICKED',
  WIZARD_TOPICS_DISPLAYED = 'WIZARD_TOPICS_DISPLAYED',
  WIZARD_TOPICS_CLICKED = 'WIZARD_TOPICS_CLICKED',
  WIZARD_FORMATS_DISPLAYED = 'WIZARD_FORMATS_DISPLAYED',
  WIZARD_FORMATS_CLICKED = 'WIZARD_FORMATS_CLICKED',
  WIZARD_PROFILE_GENDER_ASKED = 'WIZARD_PROFILE_GENDER_ASKED', // _PROFILE_
  WIZARD_PROFILE_GENDER_FILLED = 'WIZARD_PROFILE_GENDER_FILLED', // _PROFILE_
  WIZARD_PROFILE_GENDER_UPDATED = 'WIZARD_PROFILE_GENDER_UPDATED', // _PROFILE_
  WIZARD_PROFILE_GENDER_SKIPPED = 'WIZARD_PROFILE_GENDER_SKIPPED', // _PROFILE_
  WIZARD_PROFILE_BIRTHDATE_ASKED = 'WIZARD_PROFILE_BIRTHDATE_ASKED', // _PROFILE_
  WIZARD_PROFILE_BIRTHDATE_FILLED = 'WIZARD_PROFILE_BIRTHDATE_FILLED', // _PROFILE_
  WIZARD_PROFILE_BIRTHDATE_UPDATED = 'WIZARD_PROFILE_BIRTHDATE_UPDATED', // _PROFILE_
  WIZARD_PROFILE_BIRTHDATE_SKIPPED = 'WIZARD_PROFILE_BIRTHDATE_SKIPPED', // _PROFILE_
  WIZARD_PROFILE_LOCATION_ASKED = 'WIZARD_PROFILE_LOCATION_ASKED', // _PROFILE_
  WIZARD_PROFILE_LOCATION_FILLED = 'WIZARD_PROFILE_LOCATION_FILLED', // _PROFILE_
  WIZARD_PROFILE_LOCATION_UPDATED = 'WIZARD_PROFILE_LOCATION_UPDATED', // _PROFILE_
  WIZARD_PROFILE_LOCATION_SKIPPED = 'WIZARD_PROFILE_LOCATION_SKIPPED', // _PROFILE_
  WIZARD_FINISHED = 'WIZARD_FINISHED',
  WIZARD_INTERRUPTED = 'WIZARD_INTERRUPTED',

  SUGGESTION_DISPLAYED = 'SUGGESTION_DISPLAYED',
  SUGGESTION_CLICKED = 'SUGGESTION_CLICKED',
  SUGGESTION_DIRECT_JOIN = 'SUGGESTION_DIRECT_JOIN',
  SUGGESTION_JOIN = 'SUGGESTION_JOIN',
}

export enum EVENT_ORIGIN {
  AUTOMATIC = 'automatic-join', // from an automatic subscription through query parameters (ads/optimize)
  ALL_COMMUNITIES_LIST = 'all-communities-list', // from the all communities list page
  COMMUNITIES_SUGGESTION = 'communities-suggestion', // from the communities list page with user wizard settings matching
  COMMUNITIES_ALL = 'communities-all', // from the communities list page without wizard settings filters
  COMMUNITIES_TAG = 'communities-tag', // from the communities page when user select a specific tag
  COMMUNITY_RELATED_COMMUNITIES = 'community-related-communities', // from the related communities on community page
  COMMUNITY_POPIN_COMMUNITY = 'community-popin-community', // from the community's popin that suggests to join the community
  COMMUNITY_POPIN_RELATED_COMMUNITIES = 'community-popin-related-communities', // from the related communities on community page popin
  COMMUNITY_SHARE = 'community-share', // from a shared link to the community page
  COMMUNITY_WIDGET = 'community-widget', // from a community widget
  GUIDE_TEXT_LINK = 'guide-text-link', // from a guide card
  GUIDE_CARD = 'guide-card', // from a guide card
  GUIDE_POPIN = 'guide-popin', // from a guide popin
  GUIDE_POPIN_2 = 'guide-popin-2', // from a guide 2nd popin
  HOME_SUGGESTION = 'home-suggestion', // from the communities that are displayed on the home page
  PRAYER_SPACE_SUGGESTION = 'prayer-space-suggestion', // from the communities suggestions of the user prayer space
  PROFILE_ANIMATOR = 'profile-animator', // from the animator tab of a user's profile page
  PROFILE_MEMBER = 'profile-member', // from the member tab of a user's profile page
  PUBLICATION_POPIN_PARENT_COMMUNITY = 'publication-popin-parent-community', // from the publication's popin that suggests to join the parent community
  PUBLICATION_POPIN_RELATED_COMMUNITIES = 'publication-popin-related-communities', // from the publication's popin that suggests to join some related community
  PUBLICATION_BOTTOM_BLOCK = 'publication-bottom-block',
  PUBLICATION_OPTIONS_HEADER = 'publication-options-header',
  PUBLICATION_OPTIONS_VIEW_PUBLICATIONS = 'publication-options-view-publications',
  PUBLICATION_FOOTER_BANNER = 'publication-footer-banner',
}

export enum PUSH_EVENT_LABEL {
  CTA_ACCEPT_NOTIF = 'Cta-Accept-Notif',
  SETTINGS_PAGE = 'Settings-Page',
}

export enum PROMPT_EVENT_LABEL {
  PUBLICATION_S_MOTHER_COMMUNITY = "Publication's Mother Community",
  COMMUNITY_S_MOTHER_COMMUNITY = "Community's Mother Community",
  COMMUNITIES_LINKED_WITH_PUBLICATION = 'Communities Linked With Publication',
  COMMUNITIES_LINKED_WITH_COMMUNITY = 'Communities Linked With Community',
}

export const EVENT_LABELS = {
  PUSH: PUSH_EVENT_LABEL,
  PROMPT: PROMPT_EVENT_LABEL,
}

export enum LOGGED {
  IN = 'logged_in',
  OUT = 'logged_out',
}

enum DISPLAY_MODE {
  BROWSER = 'browser',
  PWA_ANDROID = 'pwa_android',
  PWA_IOS = 'pwa_ios',
  TWA_ANDROID = 'twa_android',
  APP_IOS = 'app_ios',
}

export enum SERVICE_WORKER {
  ACTIVE = 'sw_active',
  INACTIVE = 'sw_inactive',
}

export enum LOG_STATE {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export const DIMENSIONS = {
  LOG_STATE,
  DISPLAY_MODE,
  SERVICE_WORKER,
  LOGGED,
  LANG: Locale,
}

/**
 * These dimensions are also defined in the GTM dashboard as Variables.
 * Changes here must be mirrored there.
 */
export type TGTMDimensions = {
  isLoggedIn?: LOGGED
  displayMode?: DISPLAY_MODE
  serviceWorker?: SERVICE_WORKER
  isOnline?: LOG_STATE
  lang?: string
}

type TCommunityInfos = {
  community_id: number
  community_name: string
}

/**
 * These variables are also defined in the GTM dashboard.
 * Changes here must be mirrored there.
 */
export type TGTMVariables = {
  algo?: string
  appVisible?: boolean
  authSource?: GTM_AUTH_SOURCE
  bannerName?: string
  bannerCampaign?: string
  bannerCampaignViews?: number
  communityInfos?: TCommunityInfos
  connectMethod?: 'Loggedin' | 'Login' | 'Signup' | 'Reset-Password'
  donationSource?: 'Header'
  error?: string
  eventLabel?: PUSH_EVENT_LABEL | PROMPT_EVENT_LABEL
  fireworksClickCount?: number
  firstTime?: boolean
  firstJoin?: boolean
  from?: string
  interruptedOn?: string
  invalidStep?: TConnectStep
  method?: CONNECT_METHOD
  pageTitle?: string
  path?: string
  referrer?: string
  trackingSource?: string
  vignetPosition?: number
  wizardFirstTime?: boolean
  publicationIsAvailable?: boolean
} & TGTMDimensions
