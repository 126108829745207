import type { Properties } from 'csstype'
import React, { FocusEventHandler, MutableRefObject, Ref, TextareaHTMLAttributes } from 'react'
import styled, { DefaultTheme, css } from 'styled-components'

import { useTranslate } from '@hozana/intl/useTranslate'
import { defaultStyledConfig } from '@hozana/styling/config'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'
import { mergeRefs } from '@hozana/utils/functions/refs'

const responsiveProps = { fontSize: 'font-size', w: 'width', h: 'height', p: 'padding', maxH: 'max-height' } as const

type TStyledTextareaProps = {
  bold?: boolean
  bg?: keyof DefaultTheme['colors']
  color?: keyof DefaultTheme['colors']
  noBorder?: boolean
  borderRadius?: keyof DefaultTheme['borderRadius']
  resize?: Properties['resize']
} & TResponsiveProps<typeof responsiveProps> &
  TextareaHTMLAttributes<HTMLTextAreaElement>

export const StyledTextarea = styled('textarea')
  .withConfig<TTextareaProps>(defaultStyledConfig(['wrap']))
  .attrs<TStyledTextareaProps, TStyledTextareaProps>(
    ({ bg = 'white', bold, color, h, noBorder, resize, rows = 5, theme }) => ({
      rows: !h ? rows : undefined,
      style: {
        resize,
        ...(!noBorder && {
          border: 'border: 1px solid #ccc',
          boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
        }),
        ...(bold && { fontWeight: 'bold' }),
        ...(color && { color: theme.colors[color] }),
        ...(bg && { backgroundColor: theme.colors[bg] }),
      },
    }),
  )`
  touch-action: pan-x pan-y; // Prevent Firefox to zoom on the textarea on focus when it does not have a 100% width (MessageForm)
  ${({ resize }) => resize && `resize: ${resize};`}
  display: block;
  ${({ fontSize = { xs: '1em', sm: '1em' }, w = '100%', h, p = '10px', ...otherProps }) =>
    responsiveStyle(responsiveProps)({ fontSize, w, h, p, ...otherProps })}
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  line-height: 1.5em;
  border-radius: ${({ borderRadius = 'normal', theme }) => theme.borderRadius[borderRadius]};
  ${({ noBorder }) => !noBorder && 'border: 1px solid #ccc'};
  ${({ bold }) => bold && 'font-weight: bold'};
  ${({ noBorder }) =>
    !noBorder &&
    'box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);'} transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `} &:focus {
    ${({ noBorder, theme }) => !noBorder && `border-color: ${theme.colors.blue}`};
    outline: 0;
  }
`

export type TTextareaProps = {
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  forwardedRef?: Ref<HTMLTextAreaElement> | MutableRefObject<HTMLTextAreaElement>
} & TStyledTextareaProps

const ForwardRefTextarea = React.forwardRef<HTMLTextAreaElement, TTextareaProps>((props, ref) => {
  const placeholder = useTranslate(props.placeholder)

  return <StyledTextarea {...props} ref={mergeRefs(props.forwardedRef, ref)} placeholder={placeholder} />
})

// ESList obliges to set a displayName for a ForwardRef Component ?
ForwardRefTextarea.displayName = 'ForwardRefTextarea'

export const Textarea = styled(ForwardRefTextarea)``
