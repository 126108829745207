import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { DefaultTheme } from 'styled-components'

import { getAllActiveLocales, getNewLocaleUrlObject } from '@hozana/intl/functions'
import { useRouter } from '@hozana/router'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'
import { QA } from '@hozana/tests/constants'

import { AbsoluteDiv, TAbsoluteDivProps } from 'elements/layout/AbsoluteDiv'
import type { TCellProps } from 'elements/layout/Cell'
import { Div } from 'elements/layout/Div'
import type { TFixedDivProps } from 'elements/layout/FixedDiv'
import { Overlay } from 'elements/layout/Overlay'
import { Ul } from 'elements/layout/Ul'
import { Icon } from 'elements/media/Icon'
import { Ellipsis } from 'elements/text/Ellipsis'

import { ICON } from 'config/icons'
import { setLangCookie } from 'general/functions'
import { PopoverButton } from 'general/structure/Popover/PopoverButton'
import { LANG_NAMES, TLocale } from 'i18n/constants'
import { PAGE } from 'routes/constants'

const responsiveProps = { fontSize: 'font-size' } as const

type TStyledCellProps = TResponsiveProps<typeof responsiveProps> & TCellProps

const StyledDiv = styled(Div)<TStyledCellProps>`
  ${responsiveStyle(responsiveProps)}
  font-size: ${({ theme }) => theme.sizes.headerItem};

  /* Header size toggling on HomePage #headerSizeToggling */
  & span {
    transition: font-size 0.3s;
  }
  & span.big-header-span {
    font-size: 1em !important;
  }
`

const FloatingWrapper = styled(AbsoluteDiv)<TAbsoluteDivProps>`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  overflow: hidden;
`

type TLangButtonProps = {
  color?: keyof DefaultTheme['colors']
  currentLang: TLocale
} & TStyledCellProps

export const LangButton: React.FC<TLangButtonProps> = ({ currentLang, ...otherProps }) => (
  <StyledDiv
    p="0 20px 0 1em"
    data-testid={QA.COMMON.LOCALE_TOGGLE_BUTTON}
    align="middleBetween"
    {...otherProps}
    id="Language__button"
  >
    <Ellipsis color="darkBlue" fontSize="0.3em" lineHeight="2.5em">
      {LANG_NAMES[currentLang]}
    </Ellipsis>
    <Icon name={ICON.CHEVRON_DOWN} m="0 0 0 10px" color="darkBlue" size="0.43em" />
  </StyledDiv>
)

type TStyledOverlayProps = { isOpen?: boolean } & TFixedDivProps

/**
 * This component must be rendered even if it is not displayed,
 * so that it can be displayed on HomePage via full-ssr-functions.
 * The 3 css properties are those used by full-ssr-functions to display/hide.
 */
const StyledOverlay = styled(Overlay)<TStyledOverlayProps>`
  transition: background-color 0.3s;
  overflow: hidden;
`

const StyledPopoverButton = styled(PopoverButton)`
  width: 105% !important;
  display: block !important;
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  & div {
    border-radius: ${({ theme }) => theme.borderRadius.medium};
  }
`

export const LanguageItem: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const { pathname, query, isFallback } = useRouter()
  const [isOpen, setOpen] = useState(false)

  return (
    <Div w="fit-content">
      <StyledOverlay
        id="Language__overlay"
        h={isOpen ? 'auto' : '0px'}
        noOverflow={!isOpen}
        bg={!isOpen ? 'transparent' : undefined}
        onClick={() => setOpen(!isOpen)}
      />
      <LangButton currentLang={language} onClick={() => setOpen(!isOpen)} />
      <FloatingWrapper
        id="Language__content"
        zIndex="popover"
        bg="white"
        align="left"
        role="dialog"
        aria-modal="true"
        w="130px"
        h={isOpen ? 'auto' : '0px'}
        noOverflow={!isOpen}
        data-testid={QA.COMMON.LOCALES_WRAPPER}
      >
        <Ul m="-3px">
          {getAllActiveLocales()
            // Show “it” or “pl” to switch to these languages only on the home page (#seo)
            .filter((locale) => (pathname !== PAGE.HOME ? !['it', 'pl'].includes(locale) : true))
            .map(
              (locale) =>
                locale !== language &&
                // The !isFallback condition is needed to make sure the page params (ex: [communityId]) are available in query - #isFallbackCondition
                !isFallback && (
                  <StyledPopoverButton
                    data-testid={QA.COMMON.LOCALE_ITEM_BUTTON(locale)}
                    w="104%"
                    key={locale}
                    onClick={() => setLangCookie(locale)}
                    to={getNewLocaleUrlObject({ pathname, query })}
                    locale={locale}
                    // When switching language, we have to reload the app, otherwise i18next won't have the correct keys
                    reload
                  >
                    {LANG_NAMES[locale]}
                  </StyledPopoverButton>
                ),
            )}
        </Ul>
      </FloatingWrapper>
    </Div>
  )
}
