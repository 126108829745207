import styled, { DefaultTheme } from 'styled-components'

import { defaultStyledConfig } from '@hozana/styling/config'
import { TImageSize, TImageSrc, getImageUrl } from '@hozana/styling/image'
import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

import { Div, TDivProps } from '../layout/Div'

const responsiveProps = {
  bgSize: 'background-size',
  bgPosition: 'background-position',
  attachment: 'background-attachment',
} as const

export type TBgImageProps = {
  src: string | number | TImageSrc
  imageSize?: TImageSize
  bg?: keyof DefaultTheme['colors']
} & TResponsiveProps<typeof responsiveProps> &
  Omit<Partial<TDivProps>, 'bg'>

export const BgImage = styled(Div)
  .withConfig<TBgImageProps>(defaultStyledConfig())
  .attrs<TBgImageProps, TDivProps>(({ bg = 'black30', imageSize, role = 'img', src, style, theme }) => {
    const { url, webpUrl } = getImageUrl(src, imageSize, true)

    return {
      role,
      style: mergeStyles(style, {
        ...(bg && { backgroundColor: theme.colors[bg] }),
        ...(src && { backgroundImage: `url(${webpUrl || url})` }),
      }),
    }
  })`
  background-repeat: no-repeat;
  ${({ attachment, bgPosition = 'left top', bgSize = 'cover', ...otherProps }) =>
    responsiveStyle(responsiveProps)({ attachment, bgPosition, bgSize, ...otherProps })}
`
