import React, { useState } from 'react'
import styled from 'styled-components'

import { useQuery } from '@hozana/hooks/useQuery'
import { cookie } from '@hozana/storage/cookies'

import { Button } from 'elements/button/Button'
import { FixedDiv } from 'elements/layout/FixedDiv'

import { ICON } from 'config/icons'
import { useBottomOffset } from 'general/hooks/useBottomOffset'
import { COOKIES, COOKIES_CONFIG } from 'general/managers/cookies/constants'
import { E2E_QUERY_KEY } from 'general/managers/debugger/constants'

const StyledButton = styled(Button)`
  padding: 2px;
`

export const E2eHandler: React.FC = () => {
  const [e2eDB, setE2eDB] = useState<boolean>(cookie.load(COOKIES.e2eDB))

  const bottomOffset = useBottomOffset()

  const removeE2eCookie = () => {
    cookie.remove(COOKIES.e2eDB, COOKIES_CONFIG.SESSION)
    console.log('E2e mode disabled: requests will be made to hozana DB.')
  }

  useQuery(
    (query) => {
      if (!e2eDB && query[E2E_QUERY_KEY] === 'true') {
        cookie.save(COOKIES.e2eDB, true, COOKIES_CONFIG.SESSION)
        console.log('E2e mode enabled: requests will be made to e2e DB.')
        setE2eDB(true)
      }
      if (e2eDB && query[E2E_QUERY_KEY] === 'false') {
        removeE2eCookie()
        setE2eDB(false)
      }
    },
    [e2eDB],
    E2E_QUERY_KEY,
  )

  const disableE2e = () => {
    if (e2eDB) {
      removeE2eCookie()
    }
    setE2eDB(false)
  }

  return e2eDB ? (
    <FixedDiv left bottom zIndex="intentionsPanelButton" noPrint m={`0 0 ${bottomOffset}px`}>
      <StyledButton icon={ICON.CLOSE} rightSideIcon colors="yellow" reverseColors size="small" onClick={disableE2e}>
        E2EDB
      </StyledButton>
    </FixedDiv>
  ) : null
}
