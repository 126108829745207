import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useIsRequestUpToDate } from '@hozana/api/hooks/useIsRequestUpToDate'
import { useIsHydrated } from '@hozana/hooks/useIsHydrated'
import { useRouter } from '@hozana/router'

import { closeNavigation } from 'general/actions'
import { FULL_STATIC_PAGES, HEADER_ITEM } from 'general/constants'
import type { THeaderItems } from 'general/types'
import { PAGE } from 'routes/constants'

import { getUserRole } from 'modules/community/functions'
import { getCommunityQuery } from 'modules/community/queries'
import type { TCommunity } from 'modules/community/types'
import { getPublicationQuery } from 'modules/publication/queries'
import type { TPublication } from 'modules/publication/types'
import { useUser } from 'modules/user/hooks/useUser'

export const useHeaderMap = ({
  community,
  publication,
}: {
  community: TCommunity
  publication: TPublication
  correspondentId?: number
}) => {
  const dispatch = useDispatch()
  const isHydrated = useIsHydrated()
  const { isLogged } = useUser()
  const { pathname } = useRouter()
  const [prevLocation, setPrevLocation] = useState<PAGE | undefined>()
  const [isFirstLocation, setIsFirstLocation] = useState(true)
  const isCommunityUpToDate = useIsRequestUpToDate(community && getCommunityQuery({ communityId: community.id }))
  const isPublicationUpToDate = useIsRequestUpToDate(
    publication && getPublicationQuery({ publicationId: publication.id }),
  )
  const isDataUpToDate = isCommunityUpToDate && isPublicationUpToDate

  useEffect(() => {
    if (prevLocation !== pathname) {
      dispatch(closeNavigation())
      if (!([PAGE.AUTH_SOCIAL, PAGE.HOME] as PAGE[]).includes(prevLocation)) {
        setIsFirstLocation(false)
      }
      setPrevLocation(pathname)
    }
  }, [dispatch, pathname, prevLocation])

  const isPrivate = community?.isPrivate
  const isDraft = community && ['draft', 'check'].includes(community.status)

  const isMember = getUserRole(community).isMember
  const communityItems: HEADER_ITEM[] = [
    community && isDataUpToDate && !isMember && !isDraft && HEADER_ITEM.COMMUNITY_JOIN_BUTTONS,
    community && isDataUpToDate && isMember && HEADER_ITEM.INVITE_BLOCK,
  ]
  const connectItems: HEADER_ITEM[] = [
    !isLogged && isHydrated && HEADER_ITEM.LOGIN_BUTTON,
    !isLogged && isHydrated && HEADER_ITEM.SIGN_UP_BUTTON,
    isLogged && HEADER_ITEM.DONATION,
  ]
  const loggedInDesktopTabs = [
    HEADER_ITEM.FEED_PAGE_TAB,
    HEADER_ITEM.COMMUNITIES_TAB,
    HEADER_ITEM.INTENTION_TAB,
    HEADER_ITEM.MESSAGE_TAB,
  ]

  const defaultDesktopItems: THeaderItems = {
    left: [
      !FULL_STATIC_PAGES.includes(pathname) && HEADER_ITEM.NAVIGATION_BUTTON,
      !isLogged && isHydrated && HEADER_ITEM.LANGUAGE_ITEM_BLOCK,
    ],
    middle: [...(isLogged ? loggedInDesktopTabs : [])],
    right: connectItems,
  }
  const defaultMobileItems: THeaderItems = {
    left: [
      !isFirstLocation ? HEADER_ITEM.GO_BACK : !FULL_STATIC_PAGES.includes(pathname) && HEADER_ITEM.NAVIGATION_BUTTON,
    ],
    middle: [HEADER_ITEM.TITLE_PAGE],
    right: isLogged ? [HEADER_ITEM.DONATION] : [],
  }

  let desktopItems: THeaderItems
  let mobileItems: THeaderItems
  // let mobileBg: keyof DefaultTheme['colors'] = 'transparent' // Keep if necessary
  // let desktopBg: keyof DefaultTheme['colors'] = 'transparent' // Keep if necessary

  switch (pathname) {
    case PAGE.LEAVE:
      mobileItems = {
        left: [],
        middle: [HEADER_ITEM.LOGO],
        right: [],
      }
      desktopItems = {
        left: [],
        middle: [HEADER_ITEM.LOGO],
        right: [],
      }
      break

    case PAGE.INTENTIONS:
      mobileItems = {
        left: [HEADER_ITEM.NAVIGATION_BUTTON],
      }
      break

    case PAGE.USER_FEED:
      mobileItems = {
        left: [HEADER_ITEM.NAVIGATION_BUTTON],
        right: connectItems,
      }
      break

    case PAGE.COMMUNITY_VIEW:
    case PAGE.COMMUNITY_VIEW_LANDING:
    case PAGE.COMMUNITY_VIEW_UNPUBLISHED:
      mobileItems = {
        right: communityItems,
      }
      desktopItems = {
        right: communityItems,
      }
      break

    case PAGE.COMMUNITY_LIST:
      mobileItems = {
        left: [HEADER_ITEM.NAVIGATION_BUTTON],
        middle: [HEADER_ITEM.SEARCH_FORM],
        right: !isLogged && isHydrated ? [HEADER_ITEM.LOGIN_BUTTON, HEADER_ITEM.SIGN_UP_BUTTON] : [],
      }
      break

    case PAGE.PUBLICATION_VIEW:
      mobileItems = {
        right: isPrivate ? connectItems : [HEADER_ITEM.SHARE_SOCIAL_BLOCK, HEADER_ITEM.OPEN_IN_APP],
        middle: [],
      }
      desktopItems = {
        right: isPrivate ? connectItems : [HEADER_ITEM.SHARE_SOCIAL_BLOCK],
      }
      break

    case PAGE.GUIDE:
    case PAGE.GUIDES_LIST:
    case PAGE.COMMUNITY_LIST_SEO:
      mobileItems = {
        left: [HEADER_ITEM.LOGO],
        middle: [],
        right: isLogged ? [] : [HEADER_ITEM.TO_HOZANA_LINK],
      }
      desktopItems = {
        left: [!isLogged && HEADER_ITEM.LOGO],
        middle: [...(isLogged ? loggedInDesktopTabs : [])],
        right: !isLogged && isHydrated ? [HEADER_ITEM.TO_HOZANA_LINK, HEADER_ITEM.LOGIN_LINK] : [],
      }
      break

    case PAGE.PUBLICATION_CREATE:
    case PAGE.COMMUNITY_EDIT:
    case PAGE.COMMUNITY_ANNOUNCEMENTS:
    case PAGE.COMMUNITY_ROLES:
    case PAGE.COMMUNITY_STATISTICS:
      mobileItems = {
        left: community ? [HEADER_ITEM.BACK_TO_COMMUNITY_ADMIN] : defaultMobileItems.left,
      }
      desktopItems = {
        left: community ? [HEADER_ITEM.BACK_TO_COMMUNITY_ADMIN] : defaultDesktopItems.left,
      }
      break

    case PAGE.PUBLICATION_EDIT:
      mobileItems = {
        left: publication ? [HEADER_ITEM.BACK_TO_PUBLICATION] : defaultMobileItems.left,
      }
      desktopItems = {
        left: publication ? [HEADER_ITEM.BACK_TO_PUBLICATION] : defaultDesktopItems.left,
      }
      break

    case PAGE.UPLOAD_AVATAR:
      mobileItems = {
        left: [HEADER_ITEM.GO_BACK],
        middle: [],
        right: [],
      }
      desktopItems = {
        left: [HEADER_ITEM.GO_BACK],
        middle: [],
        right: [],
      }
      break
  }

  return {
    mobile: { ...defaultMobileItems, ...mobileItems },
    desktop: { ...defaultDesktopItems, ...desktopItems },
    // mobileBg,
    // desktopBg,
  }
}
