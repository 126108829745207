import { useSelector } from '@hozana/redux/hooks'

import { getCookieBarOpened } from 'general/managers/cookies/functions'
import { hasAcceptedCookies, isLangBarOpen } from 'general/selectors'

export const useBottomOffset = () => {
  const langBarOpen = useSelector(isLangBarOpen)
  const isCookieBarOpen = useSelector((state) => getCookieBarOpened(hasAcceptedCookies(state)))

  return Math.max(langBarOpen ? 82 : 0, isCookieBarOpen ? 51 : 0, 60)
}
