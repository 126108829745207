import { useSelector } from '@hozana/redux/hooks'

import { isHydrated } from 'general/selectors'

/**
 * useIsHydrated will return true once hydrated, false otherwise
 *
 * It is usefull to prevent hydration conflicts that could occur when using condition like `__SERVER__ ? <Foo /> : <Bar />`
 */
export const useIsHydrated = () => useSelector(isHydrated)
