import styled, { DefaultTheme } from 'styled-components'

import { Div, TDivProps } from 'elements/layout/Div'

export type TGradientDivProps = {
  firstColor?: keyof DefaultTheme['colors']
  middleColor?: keyof DefaultTheme['colors']
  lastColor?: keyof DefaultTheme['colors']
  angle?: number
  borderRadius?: string
} & TDivProps

export const GradientDiv = styled(Div)<TGradientDivProps>`
  background: ${(p) => p.theme.colors[p.firstColor]};
  background: linear-gradient(
    ${({ angle = 45 }) => `${angle}deg`},
    ${({ theme, firstColor = 'orange' }) => theme.colors[firstColor]} 0%,
    ${({ theme, middleColor }) => (middleColor ? `${theme.colors[middleColor]} 50%,` : '')}
      ${({ theme, lastColor = 'yellow' }) => theme.colors[lastColor]} 100%
  );
  border-radius: ${({ borderRadius }) => borderRadius};
`
