import { cookie } from '@hozana/storage/cookies'

import { COOKIES, COOKIES_CONFIG } from 'general/managers/cookies/constants'

export function saveSponsorInfos(uik: string) {
  cookie.save(COOKIES.sponsorUik, uik, COOKIES_CONFIG.ONE_YEAR)
}

export function clearSponsorInfos() {
  cookie.remove(COOKIES.sponsorUik, COOKIES_CONFIG.ONE_YEAR)
}

export function getSponsorInfos(): string {
  return cookie.load(COOKIES.sponsorUik)
}
