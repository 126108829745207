import React, { useEffect, useState } from 'react'

import { useRouter } from '@hozana/router'

import { Cell } from 'elements/layout/Cell'
import type { TDivProps } from 'elements/layout/Div'
import { FixedDiv } from 'elements/layout/FixedDiv'
import { Row } from 'elements/layout/Row'
import { Shadow } from 'elements/layout/Shadow'

import { HEADER_SUB_ID, STATUS_TYPE } from 'general/constants'
import { PAGE } from 'routes/constants'

import { useIntentionNotificationsCount } from 'modules/intention/hooks/useIntentionNotificationsCount'

import { CommunitiesTab, FeedPageTab } from './HeaderItemsComponent'
import { StatusTab } from './StatusTab'

type TSubHeaderMobileProps = Pick<TDivProps, 'bg'>

export const SubHeaderMobile: React.FC<TSubHeaderMobileProps> = () => {
  const { pathname } = useRouter()
  // Desynchronize targetPathname from real pathname to give immediate feedback on clic
  const [targetPathname, setTargetPathname] = useState(pathname)

  // Update targetPathname on pathname change
  useEffect(() => setTargetPathname(pathname), [pathname])

  const intentionTabNotificationsCount = useIntentionNotificationsCount()

  const createOnClick = (pathname: PAGE) => () => setTargetPathname(pathname)

  const getSelected = (...pages: PAGE[]) => pages.includes(targetPathname)
  const w = 1 / 4

  /** Screen is defined to "xs: true" by default in SSR, even on desktop,
   * so we force that component to be visible only on mobile, by CSS
   */
  return (
    <FixedDiv bottom right left bg="white" visible="xs" zIndex="header" id={HEADER_SUB_ID}>
      <Shadow x="-3px" y="0">
        <Row nowrap h="100%">
          <Cell w={w}>
            <FeedPageTab onClick={createOnClick(PAGE.USER_FEED)} selected={getSelected(PAGE.USER_FEED)} />
          </Cell>
          <Cell w={w}>
            <CommunitiesTab onClick={createOnClick(PAGE.COMMUNITY_LIST)} selected={getSelected(PAGE.COMMUNITY_LIST)} />
          </Cell>
          <Cell w={w}>
            <StatusTab
              type={STATUS_TYPE.INTENTIONS}
              onClick={createOnClick(PAGE.INTENTIONS)}
              number={intentionTabNotificationsCount}
              selected={getSelected(PAGE.INTENTIONS, PAGE.INTENTIONS_LANDING)}
              isDot
            />
          </Cell>
          <Cell w={w}>
            <StatusTab
              type={STATUS_TYPE.MESSAGES}
              onClick={createOnClick(PAGE.CONVERSATIONS)}
              selected={getSelected(PAGE.CONVERSATION, PAGE.CONVERSATIONS)}
            />
          </Cell>
        </Row>
      </Shadow>
    </FixedDiv>
  )
}
